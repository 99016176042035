import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import moment from "moment";
// Login Redux States

import {
  apiError,
  userPermissionsSuccessful,
  minicountrySuccessful,
  miniStatesSuccessful,
  miniCitySuccessful,
  MiniDistrictSuccessful,
  miniAreaSuccessful,
  miniDepartmentSuccessful,
  miniFirmSuccessful,
  miniVehiclesSuccessful,
  miniCollectionExecutiveSuccessful,
  miniVehiclesNonStockSuccessful,
  purchaseAvailableStockSuccessful,
  miniCountersSuccessful,
  allCountersSuccessful,
  miniZonesSuccessful,
  miniClustersSuccessful,
  miniDivisionsSuccessful,
  miniBanksSuccessful,
  miniOccupationSuccessful,
  miniBrandsSuccessful,
  miniBrandFamilySuccessful,
  miniColorsSuccessful,
  versionColorsSuccessful,
  miniFinanciersSuccessful,
  miniMechanicListSuccessful,
  miniAddressProofSuccessful,
  miniEmissionnormsSuccessful,
  miniPrioritySuccessful,
  miniVersionSuccessful,
  miniModelsSuccessful,
  miniPercentageInterestsSuccessful,
  miniRejectReasonsSuccessful,
  miniPurchaseEnquirySuccessful,
  miniSaleQuotationSuccessful,
  miniVehicleEstimationSuccessful,
  // miniEMIdatesSuccessful,
  miniInsuranceSuccessful,
  miniShowroomSuccessful,
  miniVehicletypesSuccessful,
  miniTenuresSuccessful,
  saleQuotationStockListSuccessful,
  miniManagersSuccessful,
  purchaseStockSuccessful,
  FVRequestUsersSuccessful,
  miniRepairTypSuccessful,
  miniLeaveReasonSuccessful,
  miniUsersListSuccessful,
  miniCustomerListSuccessful,
  miniReportingToUserSuccessful,
  miniAssetListSuccessful,
  financeOVFListSuccessful,
  insurancePendingListSuccessful,
  purchaseRepairStockSuccessful,
  purchaseRefurbishedSuccessful,
  serviceMechanicSuccessful,
  miniRampListSuccessful,
  miniGodownSuccessful,
  miniReleaseVehiclesSuccessful,
  miniSeizedVehiclesSuccessful,
  miniGateInVehiclesSuccessful,
  miniVehicleEstimationByVehicleSuccessful,
  miniSeizeReleaseVehiclesSuccessful,
  miniVehiclesGateInSuccessful,
  miniGateOutTransferScreenVehiclesSuccessful,
  miniTransferOutVehiclesSuccessful
} from "./miniSlice";

// AUTH related methods
import { getList, getParamsList } from "../../helpers/Helper";
function* getUserPermissions() {
  try {
    const response = yield call(getList, "/users/userpermissions/", {});
    yield put(userPermissionsSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getMiniCountry({ payload }) {
  var data = payload;
  var params = {};

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/masters/mini/country/", data);
    yield put(minicountrySuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* getMiniStates({ payload }) {
  var data = payload;
  var params = {};

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/masters/mini/states/", data);
    yield put(miniStatesSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getMiniSeizedVehicles({ payload }) {
  var data = payload;
  var params = {};

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/godown/mini/seizedvehicle/", data);
    yield put(miniSeizedVehiclesSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getMiniTransferOutVehicles({ payload }) {
  var data = payload;
  var params = {};

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/godown/mini/transferoutvehicle/", data);
    yield put(miniTransferOutVehiclesSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getMiniSeizedReleaseVehicles({ payload }) {
  var data = payload;
  var params = {};

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/godown/mini/seize_release_vehicle/", data);
    yield put(miniSeizeReleaseVehiclesSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* getMiniGateInVehicles({ payload }) {
  var data = payload;
  var params = {};

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/godown/mini/gateinvehicle/", data);
    yield put(miniGateInVehiclesSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getMiniGateInVehicles2({ payload }) {
  var data = payload;
  var params = {};

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/godown/mini/gateinvehicle/", data);
    yield put(miniGateInVehiclesSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getMiniDistricts({ payload }) {
  var data = payload;
  var params = {};

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/districts/",
      data
    );
    yield put(MiniDistrictSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* getMiniCitys({ payload }) {
  var data = payload;
  var params = {};

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/masters/mini/city/", data);
    yield put(miniCitySuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getMiniAreas({ payload }) {
  var data = payload;
  var params = {};

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/masters/mini/area/", data);
    yield put(miniAreaSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* getMiniDepartment() {
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/departments/",
      {}
    );
    yield put(miniDepartmentSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* getFirm() {
  try {
    const response = yield call(getParamsList, "/masters/mini/firm/", {});
    yield put(miniFirmSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* getVehicles({ payload }) {
  var params = {};

  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/vehicles/",
      params
    );
    yield put(miniVehiclesSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* getReleaseVehicles({ payload }) {
  var params = {};

  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/godown/mini/releasevehicle/",
      params
    );
    yield put(miniReleaseVehiclesSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getMiniTwoVehicles({ payload }) {
  var params = {};

  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini2/vehicles/",
      params
    );
    yield put(miniVehiclesSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getMiniVehiclesGateIn({ payload }) {
  var params = {};

  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/godown/mini/gatein_release_vehicle/",
      params
    );
    yield put(miniVehiclesGateInSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getMiniGateOutTransferScreenVehicles({ payload }) {
  var params = {};

  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/godown/mini/vehicles_in_gate_in/",
      params
    );
    yield put(miniGateOutTransferScreenVehiclesSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getCollectionExecutive({ payload }) {
  var params = {};

  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/users/collectionexecutive/mini/",
      params
    );
    yield put(miniCollectionExecutiveSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* getMiniGodown({ payload }) {
  var params = {};

  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/godowns/",
      params
    );
    
    yield put(miniGodownSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getVehiclesNonStock({ payload }) {
  var params = {};
  var data = payload;
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (data[k] !== "" && k !== "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/vehicles/nonstock",
      params
    );
    yield put(miniVehiclesNonStockSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getVehicleEstimationByVehicle({ payload }) { 
  const { id, ...queryParams } = payload; // Extract `id` and the rest of the parameters
  try {
    const response = yield call(
      getParamsList,
      `/purchase/vehicleestimationbyvehicle/mini/${id}`, // Use `id` in the path
      queryParams // Send remaining parameters as query params
    );

    yield put(miniVehicleEstimationByVehicleSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}


function* PurchaseAvailableStock({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  // var params = {};
  // for (const k in payload.params) {
  //   if (Object.hasOwnProperty.call(payload.params, k)) {
  //     if (k === "currentSort" || k === "sortOrder") {
  //       params.ordering = payload.params.sortOrder + payload.params.currentSort;
  //     } else {
  //       if (payload.params[k] !== "" && k !== "no_of_pages") {
  //         params[k] = payload.params[k];
  //       }
  //     }
  //   }
  // }
  try {
    const response = yield call(
      getParamsList,
      "/purchase/availabilitystock/",
      params
    );
    yield put(purchaseAvailableStockSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getCounters({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/counters/",
      params
    );
    yield put(miniCountersSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* allCounters({payload}) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/masters/counters/all", params);
    yield put(allCountersSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* getZones({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/masters/mini/zones/", params);
    yield put(miniZonesSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* getClusters({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/clusters/",
      params
    );
    yield put(miniClustersSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* getDivisions({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/divisions/",
      params
    );
    yield put(miniDivisionsSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* getBanks() {
  try {
    const response = yield call(getParamsList, "/masters/mini/banks/", {});
    yield put(miniBanksSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* getOccupation() {
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/occupations/",
      {}
    );
    yield put(miniOccupationSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* getBrands({ payload }) {
  var data = payload;
  var params = {};

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (data[k] !== "") {
        params[k] = data[k];
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/masters/mini/brands/", params);
    yield put(miniBrandsSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* getBrandFamily({ payload }) {
  var data = payload;
  var params = {};

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (data[k] !== "") {
        params[k] = data[k];
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/brandfamily/",
      params
    );
    yield put(miniBrandFamilySuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* getColors() {
  try {
    const response = yield call(getParamsList, "/masters/mini/colors/", {});
    yield put(miniColorsSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* getVersionColors({ payload }) {
  try {
    const response = yield call(
      getParamsList,
      "/masters/model/colors/" + payload,
      {}
    );
    yield put(versionColorsSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* getFinanciers() {
  try {
    const response = yield call(getParamsList, "/masters/mini/financiers/", {});
    yield put(miniFinanciersSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}


// function* getMechanics({ payload }) {
//   console.log("getMechanics", payload);
//   var data = payload;
//   var params = {};
//   for (const k in data) {
//     if (Object.hasOwnProperty.call(data, k)) {
//       if (k === "currentSort" || k === "sortOrder") {
//         params.ordering = data.sortOrder + data.currentSort;
//       } else {
//         if (data[k] !== "" && k !== "no_of_pages") {
//           params[k] = data[k];
//         }
//       }
//     }
//   }
//   try {
//     const response = yield call(
//       getParamsList,
//       "/masters/mini/mechanics/",
//       params
//     );
//     yield put(miniMechanicListSuccessful({ response }));
//   } catch (error) {
//     yield put(apiError(error));
//   }
// }

function* getMechanics({ payload }) {
  console.log("getMechanics", payload);
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/mechanic/",
      params
    );
    yield put(miniMechanicListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getAddressProof() {
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/addressproof/",
      {}
    );
    yield put(miniAddressProofSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* getEmissionnorms() {
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/emissionnorms/",
      {}
    );
    yield put(miniEmissionnormsSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* getPriority() {
  try {
    const response = yield call(getParamsList, "/masters/mini/priority/", {});
    yield put(miniPrioritySuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* getVersion({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/micro/versions/",
      params
    );
    yield put(miniVersionSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* getModels({ payload }) {
  var params = {};

  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/masters/mini/models/", params);
    yield put(miniModelsSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* getPercentageInterests() {
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/percentageinterests/",
      {}
    );
    yield put(miniPercentageInterestsSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* getRejectReasons({ payload }) {
  var params = {};

  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/rejectreasons/",
      params
    );
    yield put(miniRejectReasonsSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* getPurchaseEnquiry({ payload }) {
  var data = payload;
  var params = {};

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/purchase/enquiry/list/",
      params
    );
    yield put(miniPurchaseEnquirySuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* getSaleQuotation() {
  try {
    const response = yield call(getParamsList, "/sale/quotation/mini/", {});
    yield put(miniSaleQuotationSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* getVehicleEstimation() {
  try {
    const response = yield call(
      getParamsList,
      "/purchase/vehicleestimation/mini/",
      {}
    );
    yield put(miniVehicleEstimationSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
// function* getEMIdates() {
//   try {
//     const response = yield call(getParamsList, "/masters/mini/emidates/", {});
//     yield put(miniEMIdatesSuccessful(response));
//     var emiDates = response.map((data) => data.name);
//       var currentDate = moment();
//       var next25Day = moment().add(25, 'days');
//       var next40Day = moment().add(40, "days");
//       var next40Month  = next40Day.format('MM');
//       var next25Month  = next25Day.format('MM');
//       // var datesList = []
//       if(parseInt(next25Month) == parseInt(next40Month)){
//        var datesList =  emiDates.map((date, i) => {
//           var d = moment(date + "-" +  next25Day.format('MM-YYYY'), "DD-MM-YYYY")
//           return d
//         });
//         console.log("datesList", datesList);
//         datesList = datesList.filter(d => {
//           return d >= next25Day && d <= next40Day
//         });
//         datesList = datesList.map(d => d.format('DD-MM-YYYY'));
//         console.log("success", datesList);
//         yield put(miniEMIdatesSuccessful(datesList));

//       }else{
//         var datesList =  emiDates.map((date, i )=> {
//           var d = moment(date + "-" +  next25Day.format('MM-YYYY'), "DD-MM-YYYY")
//           return d
//         });

//         var datesList2 =  emiDates.map((date, i) => {
//           var d = moment(date + "-" +  next40Day.format('MM-YYYY'), "DD-MM-YYYY")
//           return d
//         });

//         datesList = [...datesList, ...datesList2]

//         datesList = datesList.filter(d => {
//           return d >= next25Day && d <= next40Day
//         });
//         datesList = datesList.map(d => d.format('DD-MM-YYYY'));
//         yield put(miniEMIdatesSuccessful(datesList));
//       }

//   } catch (error) {
//     yield put(apiError(error));
//   }
// }
function* getInsurance() {
  try {
    const response = yield call(getParamsList, "/masters/mini/insurance/", {});
    yield put(miniInsuranceSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* getShowroom() {
  try {
    const response = yield call(getParamsList, "/masters/mini/showrooms/", {});
    yield put(miniShowroomSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* getVehicletypes({ payload }) {
  var data = payload;
  var params = {};

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (data[k] !== "") {
        params[k] = data[k];
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/vehicletypes/",
      params
    );
    yield put(miniVehicletypesSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* getTenures() {
  try {
    const response = yield call(getParamsList, "/masters/mini/tenures/", {});
    yield put(miniTenuresSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* SaleQuotationStockList({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/sale/quotation/list/stock",
      params
    );
    yield put(saleQuotationStockListSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* getManagers() {
  try {
    const response = yield call(
      getParamsList,
      "/permissions/mini/managers/",
      {}
    );
    yield put(miniManagersSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* PurchaseStock({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/purchase/stock/", params);
    yield put(purchaseStockSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* FVRequestUsers({ payload }) {
  console.log("FVRequestUsers", payload);
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/sale/fieldverficationusers/",
      params
    );
    yield put(FVRequestUsersSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getRepairTypes({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/repairs/",
      params
    );
    yield put(miniRepairTypSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getLeaveReason({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/leavereasons/",
      params
    );
    yield put(miniLeaveReasonSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* getUsersList({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/employeesagainstcounter/",
      params
    );
    yield put(miniUsersListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* MiniCustomers({ payload }) {
  var params = {};
  var data = payload;
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (data[k] !== "" && k !== "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/person/",
      params
    );
    yield put(miniCustomerListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}


function* MiniReportingToUsers({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/users/usermini/",
      params
    );
    yield put(miniReportingToUserSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getAssetList({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/assetmini/",
      params
    );
    yield put(miniAssetListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* FinanceOVFList({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/sale/financeovf/list/",
      params
    );
    yield put(financeOVFListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}


// function* PendingInsuranceList({ payload }) {
//   var params = {};
//   for (const k in payload) {
//     if (Object.hasOwnProperty.call(payload, k)) {
//       if (k === "type") {
//         params.type = payload.type.join(",");
//       } else {
//         if (payload[k] !== "" && k !== "no_of_pages") {
//           params[k] = payload[k];
//         }
//       }
//     }
//   }
//   try {
//     const response = yield call(
//       getParamsList,
//       "/insurancerto/insurances/pending/list",
//       params
//     );
//     yield put(insurancePendingListSuccessful(response));
//   } catch (error) {
//     yield put(apiError(error));
//   }
// }

function* PendingInsuranceList({payload}) {
  // try {
  //   const response = yield call(getParamsList, "/insurancerto/insurances/pending/list", {});
  //   yield put(insurancePendingListSuccessful(response));
  // } catch (error) {
  //   yield put(apiError(error));
  // }
  var data = payload;
  var params = {};

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (data[k] !== "") {
        params[k] = data[k];
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/insurancerto/insurances/pending/list",
      params
    );
    yield put(insurancePendingListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}


function* PurchaseRepairStock({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/purchase/repairstock/",
      params
    );
    yield put(purchaseRepairStockSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}


function* PurchaseRefurbished({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/purchase/refurbishedStock/",
      params
    );
    yield put(purchaseRefurbishedSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* MiniServiceMechanic({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/servicemechanics/",
      params
    );
    yield put(serviceMechanicSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}


function* MiniRampList({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/ramps/",
      params
    );
    yield put(miniRampListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}


export function* watchGetUserPermissions() {
  yield takeEvery("mini/getUserPermissions", getUserPermissions);
}
export function* watchGetMiniCountry() {
  yield takeEvery("mini/getMinicountry", getMiniCountry);
}
export function* watchGetMiniStates() {
  yield takeEvery("mini/getMiniStates", getMiniStates);
}
export function* watchGetMiniSeizedVehicles() {
  yield takeEvery("mini/getMiniSeizedVehicles", getMiniSeizedVehicles);
}
export function* watchGetMiniTransferOutVehicles() {
  yield takeEvery("mini/getMiniTransferOutVehicles", getMiniTransferOutVehicles);
}
export function* watchGetMiniSeizeReleaseVehicles() {
  yield takeEvery("mini/getMiniSeizeReleaseVehicles", getMiniSeizedReleaseVehicles);
}
export function* watchGetMiniGateInVehicles() {
  yield takeEvery("mini/getMiniGateInVehicles", getMiniGateInVehicles);
}
export function* watchGetMiniGateInVehicles2() {
  yield takeEvery("mini/getMiniGateInVehicles2", getMiniGateInVehicles2);
}
export function* watchGetDistricts() {
  yield takeEvery("mini/getMiniDistrict", getMiniDistricts);
}
export function* watchGetCitys() {
  yield takeEvery("mini/getMiniCity", getMiniCitys);
}
export function* watchGetAreas() {
  yield takeEvery("mini/getMiniArea", getMiniAreas);
}

export function* watchGetMiniDepartment() {
  yield takeEvery("mini/getMiniDepartment", getMiniDepartment);
}
export function* watchGetFirm() {
  yield takeEvery("mini/getMiniFirm", getFirm);
}
export function* watchGetMiniVehicles() {
  yield takeEvery("mini/getMiniVehicles", getVehicles);
}
export function* watchGetMiniVehiclesRelease() {
  yield takeEvery("mini/getMiniVehiclesRelease", getReleaseVehicles);
}
export function* watchGetMiniTwoVehicles() {
  yield takeEvery("mini/getMiniTwoVehicles", getMiniTwoVehicles);
}
export function* watchGetMiniTwoGateInVehicles(){
  yield takeEvery("mini/getMiniTwoGateInVehicles", getMiniVehiclesGateIn);
}
export function* watchGetMiniGateOutTransferScreenVehicles(){
  yield takeEvery("mini/getMiniGateOutTransferScreenVehicles", getMiniGateOutTransferScreenVehicles);
}
export function* watchGetMiniCollectionExecutive() {
  yield takeEvery("mini/getMiniCollectionExecutive", getCollectionExecutive);
}
export function* watchGetMiniGodown() {
  yield takeEvery("mini/getMiniGodown", getMiniGodown);
}
export function* watchGetMiniVehiclesNonStock() {
  yield takeEvery("mini/getMiniVehiclesNonStock", getVehiclesNonStock);
}
export function* watchGetMiniVehicleEstimationByVehicle() {
  yield takeEvery("mini/getMiniVehicleEstimationByVehicle", getVehicleEstimationByVehicle);
}
export function* watchGetPurchaseAvailableStock() {
  yield takeEvery("mini/getPurchaseAvailableStock", PurchaseAvailableStock);
}
export function* watchgetMiniCounters() {
  yield takeEvery("mini/getMiniCounters", getCounters);
}
export function* watchgetAllCounters() {
  yield takeEvery("mini/getAllCounters", allCounters);
}
export function* watchgetMiniZones() {
  yield takeEvery("mini/getMiniZones", getZones);
}
export function* watchgetMiniClusters() {
  yield takeEvery("mini/getMiniClusters", getClusters);
}
export function* getMiniDivisions() {
  yield takeEvery("mini/getMiniDivisions", getDivisions);
}
export function* watchgetMiniBanks() {
  yield takeEvery("mini/getMiniBanks", getBanks);
}
export function* watchgetMiniOccupation() {
  yield takeEvery("mini/getMiniOccupation", getOccupation);
}
export function* watchgetMiniBrands() {
  yield takeEvery("mini/getMiniBrands", getBrands);
}
export function* watchgetMiniBrandFamily() {
  yield takeEvery("mini/getMiniBrandFamily", getBrandFamily);
}
export function* watchgetMiniColors() {
  yield takeEvery("mini/getMiniColors", getColors);
}
export function* watchGetVersionColors() {
  yield takeEvery("mini/getVersionColors", getVersionColors);
}
export function* watchgetMiniFinanciers() {
  yield takeEvery("mini/getMiniFinanciers", getFinanciers);
}
export function* watchgetMiniMechanics() {
  yield takeEvery("mini/getMiniMechanics", getMechanics);
}
export function* watchgetMiniAddressProof() {
  yield takeEvery("mini/getMiniAddressProof", getAddressProof);
}
export function* watchGetMiniEmissionnorms() {
  yield takeEvery("mini/getMiniEmissionnorms", getEmissionnorms);
}
export function* watchGetMiniPriority() {
  yield takeEvery("mini/getMiniPriority", getPriority);
}
export function* watchGetMiniVersion() {
  yield takeEvery("mini/getMiniVersion", getVersion);
}
export function* watchGetMiniModels() {
  yield takeEvery("mini/getMiniModels", getModels);
}
export function* watchGetMiniPercentageInterests() {
  yield takeEvery("mini/getMiniPercentageInterests", getPercentageInterests);
}
export function* watchGetMiniRejectReasons() {
  yield takeEvery("mini/getMiniRejectReasons", getRejectReasons);
}
export function* watchGetMiniPurchaseEnquiry() {
  yield takeEvery("mini/getMiniPurchaseEnquiry", getPurchaseEnquiry);
}
export function* watchGetMiniSaleQuotation() {
  yield takeEvery("mini/getMiniSaleQuotation", getSaleQuotation);
}
export function* watchGetMiniVehicleEstimation() {
  yield takeEvery("mini/getMiniVehicleEstimation", getVehicleEstimation);
}
// export function* watchGetMiniEMIdates() {
//   yield takeEvery("mini/getMiniEMIdates", getEMIdates);
// }
export function* watchGetMiniInsurance() {
  yield takeEvery("mini/getMiniInsurance", getInsurance);
}
export function* watchGetMiniShowroom() {
  yield takeEvery("mini/getMiniShowroom", getShowroom);
}
export function* watchGetMiniVehicletypes() {
  yield takeEvery("mini/getMiniVehicletypes", getVehicletypes);
}
export function* watchGetMiniTenures() {
  yield takeEvery("mini/getMiniTenures", getTenures);
}
export function* watchGetSaleQuotationStockList() {
  yield takeEvery("mini/getSaleQuotationStockList", SaleQuotationStockList);
}
export function* watchGetMiniManagers() {
  yield takeEvery("mini/getMiniManagers", getManagers);
}
export function* watchGetPurchaseStock() {
  yield takeEvery("mini/getPurchaseStock", PurchaseStock);
}
export function* watchGetFVRequestUsers() {
  yield takeEvery("mini/getFVRequestUsers", FVRequestUsers);
}
export function* watchGetMiniRepairTypes() {
  yield takeEvery("mini/getMiniRepairTypes", getRepairTypes);
}
export function* watchGetMiniLeaveReason() {
  yield takeEvery("mini/getMiniLeaveReason", getLeaveReason);
}
export function* watchGetMiniUsersList() {
  yield takeEvery("mini/getMiniUsersList", getUsersList);
}
export function* watchGetMiniCustomers() {
  yield takeEvery("mini/getMiniCustomers", MiniCustomers);
}
export function* watchGetMiniReportingToUsers() {
  yield takeEvery("mini/getMiniReportingToUsers", MiniReportingToUsers);
}
export function* watchGetMiniAssetList() {
  yield takeEvery("mini/getMiniAssetList", getAssetList);
}
export function* watchGetFinanceOVFList() {
  yield takeEvery("mini/getFinanceOVFList", FinanceOVFList);
}
export function* watchGetInsurancePendingList() {
  yield takeEvery("mini/getInsurancePendingList", PendingInsuranceList);
}
export function* watchGetPurchaseRepairStock() {
  yield takeEvery("mini/getPurchaseRepairStock", PurchaseRepairStock);
}
export function* watchGetPurchaseRefurbished() {
  yield takeEvery("mini/getPurchaseRefurbished", PurchaseRefurbished);
}
export function* watchGetMiniServiceMech() {
  yield takeEvery("mini/getMiniServiceMech", MiniServiceMechanic);
}
export function* watchGetMiniRampList() {
  yield takeEvery("mini/getMiniRampList", MiniRampList);
}
function* miniSaga() {
  yield all([
    fork(watchGetUserPermissions),
    fork(watchGetMiniCountry),
    fork(watchGetMiniStates),
    fork(watchGetCitys),
    fork(watchGetAreas),
    fork(watchGetDistricts),
    fork(watchGetMiniDepartment),
    fork(watchGetFirm),
    fork(watchGetMiniVehicles),
    fork(watchGetMiniVehiclesRelease),
    fork(watchGetMiniTwoGateInVehicles),
    fork(watchGetMiniCollectionExecutive),
    fork(watchgetMiniCounters),
    fork(watchgetAllCounters),
    fork(watchgetMiniZones),
    fork(watchgetMiniClusters),
    fork(getMiniDivisions),
    fork(watchgetMiniBanks),
    fork(watchGetMiniGodown),
    fork(watchgetMiniOccupation),
    fork(watchgetMiniBrands),
    fork(watchGetMiniSeizeReleaseVehicles),
    fork(watchgetMiniBrandFamily),
    fork(watchgetMiniColors),
    fork(watchGetVersionColors),
    fork(watchgetMiniFinanciers),
    fork(watchgetMiniMechanics),
    fork(watchgetMiniAddressProof),
    fork(watchGetMiniEmissionnorms),
    fork(watchGetMiniPriority),
    fork(watchGetMiniVersion),
    fork(watchGetMiniModels),
    fork(watchGetMiniPercentageInterests),
    fork(watchGetMiniTransferOutVehicles),
    fork(watchGetMiniRejectReasons),
    fork(watchGetMiniPurchaseEnquiry),
    fork(watchGetMiniSaleQuotation),
    fork(watchGetMiniVehicleEstimation),
    // fork(watchGetMiniEMIdates),
    fork(watchGetMiniInsurance),
    fork(watchGetMiniShowroom),
    fork(watchGetMiniVehicletypes),
    fork(watchGetMiniGateOutTransferScreenVehicles),
    fork(watchGetMiniTenures),
    fork(watchGetMiniVehiclesNonStock),
    fork(watchGetMiniVehicleEstimationByVehicle),
    fork(watchGetPurchaseAvailableStock),
    fork(watchGetSaleQuotationStockList),
    fork(watchGetMiniManagers),
    fork(watchGetPurchaseStock),
    fork(watchGetFVRequestUsers),
    fork(watchGetMiniRepairTypes),
    fork(watchGetMiniLeaveReason),
    fork(watchGetMiniUsersList),
    fork(watchGetMiniCustomers),
    fork(watchGetMiniReportingToUsers),
    fork(watchGetMiniAssetList),
    fork(watchGetFinanceOVFList),
    fork(watchGetInsurancePendingList),
    fork(watchGetPurchaseRepairStock),
    fork(watchGetPurchaseRefurbished),
    fork(watchGetMiniServiceMech),
    fork(watchGetMiniRampList),
    fork(watchGetMiniSeizedVehicles),
    fork(watchGetMiniGateInVehicles),
    fork(watchGetMiniGateInVehicles2),
    fork(watchGetMiniTwoVehicles),
  ]);
}

export default miniSaga;
