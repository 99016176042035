import { takeEvery, fork, put, all, call, select } from "redux-saga/effects";
import moment from "moment";
import {
  apiError,
  getVehicles,
  vehiclesListSuccessful,
  isModelVisible,
  InputChangeValue,
  vehicleByIDSuccessful,
  vehicleImagesByIDSuccessful,
  getvehicleImagesByID,
  godownCheckApproveSuccessful,
  getGodownCheckApprove,
  isRejectModelVisible
} from "./seizingApprovedVehiclesSlice";
import {
  getPurchaseDoc,
  getPurchaseCheckApprove,
} from "../purchases/purchasesSlice";
import {
  getVehicleSaleDoc,
  getSalesCheckApprove,
} from "../vehicleCounterSales/vehicleCounterSalesSlice";

import { getMiniVehicles, getMiniVehiclesNonStock } from "../../mini/miniSlice";
import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";
// Auth Related Methods
import {
  getMiniBrandFamily,
  getMiniModels,
  getMiniVersion,
} from "../../mini/miniSlice";
import {
  postAdd,
  getParamsList,
  postEdit,
  postDelete,
  getList,
  patchEdit,
} from "../../../helpers/Helper";
import { serialize } from "object-to-formdata";

// function* VehiclesGet({ payload }) {
//   var data = payload;
//   var params = {};

//   for (const k in data) {
//     if (Object.hasOwnProperty.call(data, k)) {
//       if (k === "currentSort" || k === "sortOrder") {
//         params.ordering = data.sortOrder + data.currentSort;
//       } else {
//         if (data[k] != "" && k != null && k != "no_of_pages") {
//           params[k] = data[k];
//         }
//       }
//     }
//   }
//   try {
//     const response = yield call(getParamsList, "/masters/vehicles/", params);
//     yield put(vehiclesListSuccessful(response));
//   } catch (error) {
//     yield put(apiError(error));
//   }
// }

function* VehiclesGet({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if ((data[k] !== "" || typeof data[k] === "boolean") && k != null && k != "no_of_pages") {
          if (k == "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/godown/seizingvehicle/approval/list/", params);
    yield put(vehiclesListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* AddVehicles({ payload }) {
  try {
    if (payload.id == 0) {
      const response = yield call(postAdd, "/godown/seizingvehicle/approval/", payload.obj);
      if (response.status === 200 || response.status === 201) {
        const formData = serialize(payload?.profileObj);
        const response2 = yield call(
          postEdit,
          "/godown/seizingvehicleimages/" + response.data?.id,
          formData
        );

        if (response2.status === 200 || response2.status === 201) {
          Modal.success({
            icon: (
              <span className="remix-icon">
                <RiCheckboxCircleLine />
              </span>
            ),
            title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
            content: (
              <div>
                <p className="hp-p1-body hp-text-color-black-80">
                Seizing vehicle Approved successfully!
                </p>
              </div>
            ),
          });
        }
        // yield put(getVehicleEstimation({ page: 1, search: "", page_size: 10 }));
        payload.history.push("/pages/seized_vehicles_approval");
      }
      if (response.status === 201) {
        yield put(getVehicles({ page: 1, search: "", page_size: 10 }));
        yield put(isModelVisible(false));
        payload.history.push("/pages/seized_vehicles_approval");
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to added Vehicle. Please try again!
              </p>
            </div>
          ),
        });
      }
      yield put(getMiniVehicles({ page: 1, search: "", page_size: 10 }));
      yield put(
        getMiniVehiclesNonStock({ page: 1, search: "", page_size: 10 })
      );
    } else {
      const response = yield call(
        postEdit,
        `/godown/seizingvehicle/approval/${payload.id}/` ,
        payload.obj
      );
      if ((response.status === 200 || response.status === 201) && payload.has_images) {
        const formData = serialize(payload?.profileObj);
        const response2 = yield call(
          patchEdit,
          "/godown/seizingvehicleimages/" + response.data?.id,
          formData
        );
        
        if (response2.status === 200 || response2.status === 201) {
          Modal.success({
            icon: (
              <span className="remix-icon">
                <RiCheckboxCircleLine />
              </span>
            ),
            title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
            content: (
              <div>
                <p className="hp-p1-body hp-text-color-black-80">
                Seizing vehicle Images Edited successfully!
                </p>
              </div>
            ),
          });
          payload.history.push("/pages/seized_vehicles_approval");
        }

        else {
          Modal.error({
            icon: (
              <span className="remix-icon">
                <RiAlertLine />
              </span>
            ),
            title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
            content: (
              <div>
                <p className="hp-p1-body hp-text-color-black-80">
                  Sorry! Unable to edit Vehicle Images. Please try again!
                </p>
              </div>
            ),
          });
        }

        // yield put(getVehicleEstimation({ page: 1, search: "", page_size: 10 }));
        payload.history.push("/pages/vehicle_estimation");
        
        yield put(isModelVisible(false));
        payload.history.push("/pages/seized_vehicles_approval");
      }
       else {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
              Seizing vehicle Approved successfully!
              </p>
            </div>
          ),
        });
        payload.history.push("/pages/seized_vehicles_approval");
      }
      // yield put(vehiclesAddSuccessful(null));
    }
  } catch (error) {
    {
      error.vehicleno
        ? Modal.error({
            icon: (
              <span className="remix-icon">
                <RiAlertLine />
              </span>
            ),
            title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
            content: (
              <div>
                <p className="hp-p1-body hp-text-color-black-80">
                  {error.vehicleno[0]}
                </p>
              </div>
            ),
          })
        : Modal.error({
            icon: (
              <span className="remix-icon">
                <RiAlertLine />
              </span>
            ),

            title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
            content: (
              <div>
                <p className="hp-p1-body hp-text-color-black-80">
                  Reject Reason is required!
                </p>
              </div>
            ),
          });
    }

    yield put(apiError(error));
  }
}
function* DeleteVehicles({ payload }) {
  var areaID = payload;
  try {
    const response = yield call(postDelete, `/godown/seizingvehicle/detail/${areaID}/` );

    if (response.status === 204) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your Vehicle has been deleted!
            </p>
          </div>
        ),
      });
      yield put(getVehicles({ page: 1, search: "", page_size: 10 }));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Your Vehicle has been not deleted!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });

    yield put(apiError(error));
  }
}

function* VehicleValidate({ payload }) {
  try {
    const response = yield call(
      postAdd,
      "/masters/vehicleno/validation/",
      payload
    );
  } catch (error) {
    if (error.response.status == 400) {
      yield put(
        InputChangeValue({
          key: "vehicleno",
          value: "",
        })
      );
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              {error.response.data.error[0]}
            </p>
          </div>
        ),
      });
    } else if (error.response.status == 500) {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Getting from server side issue!
            </p>
          </div>
        ),
      });
    }
    yield put(apiError(error));
  }
}

function* EngineValidate({ payload }) {
  try {
    const response = yield call(
      postAdd,
      "/masters/engineno/validation/",
      payload
    );
  } catch (error) {
    if (error.response.status == 400) {
      yield put(
        InputChangeValue({
          key: "engineno",
          value: "",
        })
      );
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              {error.response.data.error[0]}
            </p>
          </div>
        ),
      });
    } else if (error.response.status == 500) {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Getting from server side issue!
            </p>
          </div>
        ),
      });
    }
    yield put(apiError(error));
  }
}

function* ChassisValidate({ payload }) {
  try {
    const response = yield call(
      postAdd,
      "/masters/chassisno/validation/",
      payload
    );
  } catch (error) {
    if (error.response.status == 400) {
      yield put(
        InputChangeValue({
          key: "chassisno",
          value: "",
        })
      );
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              {error.response.data.error[0]}
            </p>
          </div>
        ),
      });
    } else if (error.response.status == 500) {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Getting from server side issue!
            </p>
          </div>
        ),
      });
    }
    yield put(apiError(error));
  }
}

function* VehicleByID({ payload }) {
  try {
    const response = yield call(getParamsList,`/godown/seizingvehicle/approval/${payload}/`,);
    yield put(getMiniBrandFamily({ brand: response.brand?.id }));
    yield put(getMiniModels({ brandfamily: response.brandfamily?.id }));
    yield put(getMiniVersion({ model: response.model?.id }));
    yield put(vehicleByIDSuccessful(response));
    yield put(getvehicleImagesByID({payload,response2 :response}))

    // yield put(isModelVisible(true));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* VehicleImagesByID({ payload}) {
  try {
    const response = yield call(
      getParamsList,
      `/godown/seizingvehicleimages/detail/${payload?.payload}`,
    );
    yield put(getMiniBrandFamily({ brand: response.brand?.id }));
    yield put(getMiniModels({ brandfamily: response.brandfamily?.id }));
    yield put(getMiniVersion({ model: response.model?.id }));
    yield put(vehicleImagesByIDSuccessful({response,response2:payload.response2}));

    // yield put(isModelVisible(true));
  } catch (error) {
    yield put(apiError(error));
  }
}

// function* UpdateFsyncStatus({ payload }) {
//   try {
//     const response = yield call(
//       postEdit,
//       "/masters/vehiclerepost/"  + payload.id, {}
//     );
//     if (response.status === 200 ) {
//       if( response.data.focus_post == true){
//         Modal.success({
//           icon: (
//             <span className="remix-icon">
//               <RiCheckboxCircleLine />
//             </span>
//           ),
//           title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
//           content: (
//             <div>
//               <p className="hp-p1-body hp-text-color-black-80">
//                 Selected Vehicle is successfully synchronised into focus
//               </p>
//             </div>
//           ),
//         });
//       }else{
//         Modal.error({
//           icon: (
//             <span className="remix-icon">
//               <RiAlertLine />
//             </span>
//           ),
//           title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
//           content: (
//             <div>
//               <p className="hp-p1-body hp-text-color-black-80">
//                 Sorry! selected Vehicle is not posted on focus. please try
//                 again!
//               </p>
//             </div>
//           ),
//         });
//       }
//       yield put(getVehicles({ page: 1, search: "", page_size: 10 }));
//     } else {
//       Modal.error({
//         icon: (
//           <span className="remix-icon">
//             <RiAlertLine />
//           </span>
//         ),
//         title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
//         content: (
//           <div>
//             <p className="hp-p1-body hp-text-color-black-80">
//               Sorry! selected Vehicle is not posted on focus. please try
//               again!
//             </p>
//           </div>
//         ),
//       });
//     }
//   } catch (error) {
//     Modal.error({
//       icon: (
//         <span className="remix-icon">
//           <RiAlertLine />
//         </span>
//       ),

//       title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
//       content: (
//         <div>
//           <p className="hp-p1-body hp-text-color-black-80">
//             Sorry! Getting from server side issue!
//           </p>
//         </div>
//       ),
//     });
//     yield put(apiError(error));
//   }
// }

function* GodownCheckApprove({ payload }) {
  try {
    const response = yield call(postAdd, "/godown/checkapprove/", payload);
    if (response.status === 200) {
      yield put(godownCheckApproveSuccessful(true));
    }
  } catch (error) {
    yield put(godownCheckApproveSuccessful(false));
    yield put(apiError(error));
  }
}

function* UpdateFsyncStatus({ payload }) {
  let vehicleParamsData = yield select(
    (state) => state.vehicles.vehiclesParams
  );
  try {
    const response = yield call(
      getParamsList,
      "/masters/vehiclerepost/" + payload.id,
      {}
    );
    if (response.post_status == true) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Selected Vehicle is successfully synchronised into focus
            </p>
          </div>
        ),
      });
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! selected Vehicle is not posted on focus. please try again!
            </p>
          </div>
        ),
      });
    }
    yield put(getVehicles(vehicleParamsData));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* SeizeApproval({ payload }) {

  try {
    if (payload.id === 0 || payload.approveObj.approvalstatus === 3) {
      let approveResponse;

      // If approvalstatus is NOT 3, call the first API
      if (payload.approveObj.approvalstatus !== 3) {
        const response = yield call(postAdd, "/godown/seizingvehicle/approval/", payload.obj);

        if (response.status !== 200 && response.status !== 201) {
          throw new Error("Seizing vehicle approval failed");
        }
      }

      // Call the second API
      approveResponse = yield call(postAdd, "/godown/approve/", payload.approveObj);

      if (approveResponse.status === 200) {
        showSuccessMessage(payload.approveObj.approvalstatus);
        payload.history.push("/pages/seized_vehicles_approval");
      } else {
        throw new Error("Approval failed");
      }
    } else {
      let approveResponse;

      // If approvalstatus is NOT 3, call the first API to update the existing vehicle
      if (payload.approveObj.approvalstatus !== 3) {
        const response = yield call(postEdit, `/godown/seizingvehicle/approval/${payload.id}/`, payload.obj);

        if (response.status !== 200 && response.status !== 201) {
          throw new Error("Seizing vehicle update failed");
        }
      }

      // Call the second API
      approveResponse = yield call(postAdd, "/godown/approve/", payload.approveObj);

      if (approveResponse.status === 200) {
        showSuccessMessage(payload.approveObj.approvalstatus);
        payload.history.push("/pages/seized_vehicles_approval");
      } else {
        throw new Error("Approval failed");
      }
    }
  } catch (error) {
    Modal.error({
      icon: <span className="remix-icon"><RiAlertLine /></span>,
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: <p className="hp-p1-body hp-text-color-black-80">Sorry! Unable to process the request. Please try again!</p>,
    });
    yield put(apiError(error));
  }
}

// Helper function to show success message based on approval status
function showSuccessMessage(approvalStatus) {
  const message = approvalStatus === 3 ? "Seizing vehicle Rejected successfully!" : "Seizing vehicle Approved successfully!";
  
  Modal.success({
    icon: <span className="remix-icon"><RiCheckboxCircleLine /></span>,
    title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
    content: <p className="hp-p1-body hp-text-color-black-80">{message}</p>,
  });
}

function* watchGetVehicles() {
  yield takeEvery("SeizingApprovedVehicle/getVehicles", VehiclesGet);
}

export function* watchVehiclesAdd() {
  yield takeEvery("SeizingApprovedVehicle/vehiclesAdd", AddVehicles);
}

export function* watchVehiclesDelete() {
  yield takeEvery("SeizingApprovedVehicle/vehiclesDelete", DeleteVehicles);
}

export function* watchVehicleNumberValidate() {
  yield takeEvery("SeizingApprovedVehicle/vehicleNumberValidate", VehicleValidate);
}

export function* watchEngineNumberValidate() {
  yield takeEvery("SeizingApprovedVehicle/engineNumberValidate", EngineValidate);
}

export function* watchChassisNumberValidate() {
  yield takeEvery("SeizingApprovedVehicle/chassisNumberValidate", ChassisValidate);
}
export function* watchGetvehicleByID() {
  yield takeEvery("SeizingApprovedVehicle/getvehicleByID", VehicleByID);
}
export function* watchGetvehicleImagesByID() {
  yield takeEvery("SeizingApprovedVehicle/getvehicleImagesByID", VehicleImagesByID);
}

export function* watchFsyncStatusUpdate() {
  yield takeEvery("SeizingApprovedVehicle/fsyncStatusUpdate", UpdateFsyncStatus);
}
export function* watchGetGodownCheckApprove() {
  yield takeEvery("SeizingApprovedVehicle/getGodownCheckApprove", GodownCheckApprove);
}
export function* watchseizingApproval() {
  yield takeEvery("SeizingApprovedVehicle/seizingApproval", SeizeApproval);
}
function* SeizingVehiclesSaga() {
  yield all([
    fork(watchGetVehicles),
    fork(watchVehiclesAdd),
    fork(watchVehiclesDelete),
    fork(watchVehicleNumberValidate),
    fork(watchEngineNumberValidate),
    fork(watchChassisNumberValidate),
    fork(watchGetvehicleByID),
    fork(watchGetvehicleImagesByID),
    fork(watchFsyncStatusUpdate),
    fork(watchGetGodownCheckApprove),
    fork(watchseizingApproval)
  ]);
}

export default SeizingVehiclesSaga;
