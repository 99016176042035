import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
var date = new Date(),
  y = date.getFullYear(),
  m = date.getMonth();
export const counterSlice = createSlice({
  name: "counters",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    counterslist: [],
    model: false,
    drawer: false,
    error_msg: null,
    rowdata: {},
    countersParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      listCount: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },

  reducers: {
    getCounters: (state) => {
      return {
        ...state,
        loading: true,
      };
    },

    counterListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.countersParams.page_size
      );

      return {
        ...state,
        listCount: action.payload.response.count,
        counterslist: action.payload.response.results,
        loading: false,
        countersParams: {
          ...state.countersParams,
          no_of_pages: noofpages,
        },
      };
    },
    counterAdd: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    counterAddSuccessful: (state, action) => {
      return {
        ...state,
        message: action.payload,
        loading: false,
        model: false,
        rowdata: {},
      };
    },
    counterEdit: (state, action) => {
      return {
        ...state,
        model: true,
        rowdata: {
          id: action.payload.id,
          name: action.payload.name,
          longitude: action.payload.longitude,
          latitude: action.payload.latitude,
          state_id: action.payload.state?.id,
          district_id: action.payload.district?.id,
          zone_id: action.payload.zone?.id,
          division_id: action.payload.division?.id,
          cluster_id: action.payload.cluster?.id,
          type: action.payload.type,
          c_type: action.payload.c_type,
          area_id: action.payload.area?.id,
          city_id: action.payload.city?.id,
          pincode: action.payload.pincode,
          address: action.payload.address,
          is_active: action.payload.is_active,
          mobile: action.payload.mobile,
          organization_id: action.payload.organization?.id,
          branchid: action.payload.branchid,
          centerid: action.payload.centerid,
        },
      };
    },
    // counterEditSuccessful: (state) => {
    //   return {
    //     ...state,
    //     loading: false,
    //     rowdata: {},
    //   };
    // },
    counterDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    InputChangeValue: (state, action) => {
      if (action.payload.key == "state_id") {
        return {
          ...state,
          rowdata: {
            ...state.rowdata,
            [action.payload.key]: action.payload.value,
            district_id: "",
            city_id: "",
            area_id: "",
          },
        };
      } else if (action.payload.key == "district_id") {
        return {
          ...state,
          rowdata: {
            ...state.rowdata,
            [action.payload.key]: action.payload.value,
            city_id: "",
            area_id: "",
          },
        };
      } else if (action.payload.key == "city_id") {
        return {
          ...state,
          rowdata: {
            ...state.rowdata,
            [action.payload.key]: action.payload.value,
            area_id: "",
          },
        };
      } else {
        return {
          ...state,
          rowdata: {
            ...state.rowdata,
            [action.payload.key]: action.payload.value,
          },
        };
      }
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    SetCountersParams: (state, action) => {
      return {
        ...state,
        countersParams: action.payload,
      };
    },
    isDrawerVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          drawer: action.payload,
          // enquiryData: {},
        };
      } else {
        return {
          ...state,
          drawer: action.payload,
        };
      }
    },
    FilterInputChangeValue: (state, action) => {
      switch (action.payload.key) {
        case "zone":
          return {
            ...state,
            countersParams: {
              ...state.countersParams,
              [action.payload.key]: action.payload.value,
              cluster: null,
              division: null,
            },
          };

        case "division":
          return {
            ...state,
            countersParams: {
              ...state.countersParams,
              [action.payload.key]: action.payload.value,
              cluster: null,
            },
          };

        case "country":
          return {
            ...state,
            countersParams: {
              ...state.countersParams,
              [action.payload.key]: action.payload.value,
              state: null,
              district: null,
              city: null,
              area: null,
            },
          };
        case "state":
          return {
            ...state,
            countersParams: {
              ...state.countersParams,
              [action.payload.key]: action.payload.value,
              district: null,
              city: null,
              area: null,
            },
          };
        case "district":
          return {
            ...state,
            countersParams: {
              ...state.countersParams,
              [action.payload.key]: action.payload.value,
              city: null,
              area: null,
            },
          };
        case "city":
          return {
            ...state,
            countersParams: {
              ...state.countersParams,
              [action.payload.key]: action.payload.value,
              area: null,
            },
          };
        default:
          return {
            ...state,
            countersParams: {
              ...state.countersParams,
              [action.payload.key]: action.payload.value,
            },
          };
      }
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getCounters,
  counterListSuccessful,
  counterAdd,
  counterAddSuccessful,
  counterEdit,
  //   counterEditSuccessful,
  counterDelete,
  InputChangeValue,
  apiError,
  isModelVisible,
  SetCountersParams,
  isDrawerVisible,
  FilterInputChangeValue,
} = counterSlice.actions;

export default counterSlice.reducer;
