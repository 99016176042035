import { takeEvery, fork, put, all, call, select } from "redux-saga/effects";
import moment from "moment";
import {
  apiError,
  getVehicles,
  vehiclesListSuccessful,
  isModelVisible,
  InputChangeValue,
  vehicleByIDSuccessful,
  vehicleImagesByIDSuccessful,
  getvehicleImagesByID,
  godownCheckApproveSuccessful,
  getGodownCheckApprove,
  isRejectModelVisible
} from "./godownTransferOutSlice";
import {
  getPurchaseDoc,
  getPurchaseCheckApprove,
} from "../purchases/purchasesSlice";
import {
  getVehicleSaleDoc,
  getSalesCheckApprove,
} from "../vehicleCounterSales/vehicleCounterSalesSlice";

import { getMiniVehicles, getMiniVehiclesNonStock } from "../../mini/miniSlice";
import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";
// Auth Related Methods
import {
  getMiniBrandFamily,
  getMiniModels,
  getMiniVersion,
} from "../../mini/miniSlice";
import {
  postAdd,
  getParamsList,
  postEdit,
  postDelete,
  getList,
  patchEdit,
} from "../../../helpers/Helper";
import { serialize } from "object-to-formdata";

// function* VehiclesGet({ payload }) {
//   var data = payload;
//   var params = {};

//   for (const k in data) {
//     if (Object.hasOwnProperty.call(data, k)) {
//       if (k === "currentSort" || k === "sortOrder") {
//         params.ordering = data.sortOrder + data.currentSort;
//       } else {
//         if (data[k] != "" && k != null && k != "no_of_pages") {
//           params[k] = data[k];
//         }
//       }
//     }
//   }
//   try {
//     const response = yield call(getParamsList, "/masters/vehicles/", params);
//     yield put(vehiclesListSuccessful(response));
//   } catch (error) {
//     yield put(apiError(error));
//   }
// }

function* VehiclesGet({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          if (k == "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/godown/godown_transfer_out/", params);
    yield put(vehiclesListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* AddVehicles({ payload }) {
  try {
    const response = yield call(postAdd, "/godown/godown_transfer_out/", payload.obj);

    if (response.status === 200 || response.status === 201) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
            Godown Transfer Out Done Successfully!
            </p>
          </div>
        ),
      });
      payload.history.push("/pages/godown_transfer_out");
      yield put(VehiclesGet({ page: 1, search: "", page_size: 10 }));
      // ✅ Ensure history exists before calling push
      
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Getting Server Side Issue!
            </p>
          </div>
        ),
      });
    }

    yield put(getMiniVehicles({ page: 1, search: "", page_size: 10 }));
  } catch (error) {
    yield put(apiError(error));
  }
}


function* DeleteVehicles({ payload }) {
  var areaID = payload;
  try {
    const response = yield call(postDelete, `/godown/seizingvehicle/detail/${areaID}/` );

    if (response.status === 204) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your Vehicle has been deleted!
            </p>
          </div>
        ),
      });
      yield put(getVehicles({ page: 1, search: "", page_size: 10 }));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Your Vehicle has been not deleted!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });

    yield put(apiError(error));
  }
}

function* VehicleValidate({ payload }) {
  try {
    const response = yield call(
      postAdd,
      "/masters/vehicleno/validation/",
      payload
    );
  } catch (error) {
    if (error.response.status == 400) {
      yield put(
        InputChangeValue({
          key: "vehicleno",
          value: "",
        })
      );
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              {error.response.data.error[0]}
            </p>
          </div>
        ),
      });
    } else if (error.response.status == 500) {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Getting from server side issue!
            </p>
          </div>
        ),
      });
    }
    yield put(apiError(error));
  }
}

function* EngineValidate({ payload }) {
  try {
    const response = yield call(
      postAdd,
      "/masters/engineno/validation/",
      payload
    );
  } catch (error) {
    if (error.response.status == 400) {
      yield put(
        InputChangeValue({
          key: "engineno",
          value: "",
        })
      );
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              {error.response.data.error[0]}
            </p>
          </div>
        ),
      });
    } else if (error.response.status == 500) {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Getting from server side issue!
            </p>
          </div>
        ),
      });
    }
    yield put(apiError(error));
  }
}

function* ChassisValidate({ payload }) {
  try {
    const response = yield call(
      postAdd,
      "/masters/chassisno/validation/",
      payload
    );
  } catch (error) {
    if (error.response.status == 400) {
      yield put(
        InputChangeValue({
          key: "chassisno",
          value: "",
        })
      );
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              {error.response.data.error[0]}
            </p>
          </div>
        ),
      });
    } else if (error.response.status == 500) {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Getting from server side issue!
            </p>
          </div>
        ),
      });
    }
    yield put(apiError(error));
  }
}

function* VehicleByID({ payload }) {
  try {
    const response = yield call(getParamsList,`/godown/godown_transfer_out/${payload}/`,);
    // yield put(getMiniBrandFamily({ brand: response.brand?.id }));
    // yield put(getMiniModels({ brandfamily: response.brandfamily?.id }));
    // yield put(getMiniVersion({ model: response.model?.id }));
    yield put(vehicleByIDSuccessful(response));
    // yield put(getvehicleImagesByID({payload,response2 :response}))

    // yield put(isModelVisible(true));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* VehicleImagesByID({ payload}) {
  try {
    const response = yield call(
      getParamsList,
      `/godown/seizingvehicleimages/detail/${payload?.payload}`,
    );
    yield put(getMiniBrandFamily({ brand: response.brand?.id }));
    yield put(getMiniModels({ brandfamily: response.brandfamily?.id }));
    yield put(getMiniVersion({ model: response.model?.id }));
    yield put(vehicleImagesByIDSuccessful({response,response2:payload.response2}));

    // yield put(isModelVisible(true));
  } catch (error) {
    yield put(apiError(error));
  }
}

// function* UpdateFsyncStatus({ payload }) {
//   try {
//     const response = yield call(
//       postEdit,
//       "/masters/vehiclerepost/"  + payload.id, {}
//     );
//     if (response.status === 200 ) {
//       if( response.data.focus_post == true){
//         Modal.success({
//           icon: (
//             <span className="remix-icon">
//               <RiCheckboxCircleLine />
//             </span>
//           ),
//           title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
//           content: (
//             <div>
//               <p className="hp-p1-body hp-text-color-black-80">
//                 Selected Vehicle is successfully synchronised into focus
//               </p>
//             </div>
//           ),
//         });
//       }else{
//         Modal.error({
//           icon: (
//             <span className="remix-icon">
//               <RiAlertLine />
//             </span>
//           ),
//           title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
//           content: (
//             <div>
//               <p className="hp-p1-body hp-text-color-black-80">
//                 Sorry! selected Vehicle is not posted on focus. please try
//                 again!
//               </p>
//             </div>
//           ),
//         });
//       }
//       yield put(getVehicles({ page: 1, search: "", page_size: 10 }));
//     } else {
//       Modal.error({
//         icon: (
//           <span className="remix-icon">
//             <RiAlertLine />
//           </span>
//         ),
//         title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
//         content: (
//           <div>
//             <p className="hp-p1-body hp-text-color-black-80">
//               Sorry! selected Vehicle is not posted on focus. please try
//               again!
//             </p>
//           </div>
//         ),
//       });
//     }
//   } catch (error) {
//     Modal.error({
//       icon: (
//         <span className="remix-icon">
//           <RiAlertLine />
//         </span>
//       ),

//       title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
//       content: (
//         <div>
//           <p className="hp-p1-body hp-text-color-black-80">
//             Sorry! Getting from server side issue!
//           </p>
//         </div>
//       ),
//     });
//     yield put(apiError(error));
//   }
// }

function* GodownCheckApprove({ payload }) {
  try {
    const response = yield call(postAdd, "/godown/checkapprove/", payload);
    if (response.status === 200) {
      yield put(godownCheckApproveSuccessful(true));
    }
  } catch (error) {
    yield put(godownCheckApproveSuccessful(false));
    yield put(apiError(error));
  }
}

function* UpdateFsyncStatus({ payload }) {
  let vehicleParamsData = yield select(
    (state) => state.vehicles.vehiclesParams
  );
  try {
    const response = yield call(
      getParamsList,
      "/masters/vehiclerepost/" + payload.id,
      {}
    );
    if (response.post_status == true) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Selected Vehicle is successfully synchronised into focus
            </p>
          </div>
        ),
      });
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! selected Vehicle is not posted on focus. please try again!
            </p>
          </div>
        ),
      });
    }
    yield put(getVehicles(vehicleParamsData));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* SeizeApproval({ payload }) {
  try {
    const { approveObj, history } = payload;
    const { id, approval_status, reject_reason } = approveObj;

    // Determine the API URL and method based on the id
    const url = `/godown/godown_transfer_out/${id && id !== "0" ? `${id}/` : ""}`;
    const method = id && id !== "0" ? postEdit : postAdd; // Ensure putEdit is defined
    const urlPayload = {
      approval_status:approval_status
    }
    if(reject_reason){
      urlPayload.reject_reason = reject_reason
    }
    const response = yield call(method, url, urlPayload);

    if (response.status === 200 || response.status === 201) {
      let successMessage =
        approval_status == 2
          ? "Godown Transfer has been approved successfully."
          : "Godown Transfer has been rejected successfully.";

      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
        content: <p className="hp-p1-body hp-text-color-black-80">{successMessage}</p>,
      });
      if (history && typeof history.push === "function") {
        history.push("/pages/godown_transfer_out");
      } 
      yield put(getVehicles({ page: 1, search: "", page_size: 10 }));
      // yield put(getGodownCheckApprove({ godown_vehicle_id: payload.godown_vehicle_id }));
      yield put(isRejectModelVisible(false));

     
    } else {
      let errorMessage =
        approval_status == 2
          ? "Sorry! Unable to approve the purchase. Please try again!"
          : "Sorry! Unable to reject the purchase. Please try again!";

      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: <p className="hp-p1-body hp-text-color-black-80">{errorMessage}</p>,
      });
    }
  } catch (error) {
    console.log("eerrro",error)
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: <p className="hp-p1-body hp-text-color-black-80">Sorry! Server-side issue encountered.</p>,
    });

    yield put(apiError(error));
  }
}


function* watchGetVehicles() {
  yield takeEvery("godownTransferOut/getVehicles", VehiclesGet);
}

export function* watchVehiclesAdd() {
  yield takeEvery("godownTransferOut/vehiclesAdd", AddVehicles);
}

export function* watchVehiclesDelete() {
  yield takeEvery("godownTransferOut/vehiclesDelete", DeleteVehicles);
}

export function* watchVehicleNumberValidate() {
  yield takeEvery("godownTransferOut/vehicleNumberValidate", VehicleValidate);
}

export function* watchEngineNumberValidate() {
  yield takeEvery("godownTransferOut/engineNumberValidate", EngineValidate);
}

export function* watchChassisNumberValidate() {
  yield takeEvery("godownTransferOut/chassisNumberValidate", ChassisValidate);
}
export function* watchGetvehicleByID() {
  yield takeEvery("godownTransferOut/getvehicleByID", VehicleByID);
}
export function* watchGetvehicleImagesByID() {
  yield takeEvery("godownTransferOut/getvehicleImagesByID", VehicleImagesByID);
}

export function* watchFsyncStatusUpdate() {
  yield takeEvery("godownTransferOut/fsyncStatusUpdate", UpdateFsyncStatus);
}
export function* watchGetGodownCheckApprove() {
  yield takeEvery("godownTransferOut/getGodownCheckApprove", GodownCheckApprove);
}
export function* watchseizingApproval() {
  yield takeEvery("godownTransferOut/seizingApproval", SeizeApproval);
}
function* godownTransferOutSaga() {
  yield all([
    fork(watchGetVehicles),
    fork(watchVehiclesAdd),
    fork(watchVehiclesDelete),
    fork(watchVehicleNumberValidate),
    fork(watchEngineNumberValidate),
    fork(watchChassisNumberValidate),
    fork(watchGetvehicleByID),
    fork(watchGetvehicleImagesByID),
    fork(watchFsyncStatusUpdate),
    fork(watchGetGodownCheckApprove),
    fork(watchseizingApproval)
  ]);
}

export default godownTransferOutSaga;
