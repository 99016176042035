import { takeEvery, fork, put, all, call, select } from "redux-saga/effects";
import moment from "moment";
import {
  apiError,
  stockPriceSuccessful,
  stockPriceCountSuccessful,
  commentListSuccessful,
  isModelVisible,
  ratingListSuccessful,
  isGuarageModelVisible,
  getStockPrice,
  GuarageRepairByIdSuccessful,
  isQuickShellModelVisible,
  addedSuccess,
  galleryListSuccessful,
  quickShellImageUploadSuccessful,
  locationListSuccessful,
  qsGalleryListSuccessful,
  qsGalleryListForModelSuccessful,
  addQsPriceSuccess,
  updateOutOfStockSuccess,
  qsGalleryListByIdSuccessful,
  qsGalleryClearedSuccessfully,
} from "./stockPriceSlice";
import {
  getList,
  getParamsList,
  postAdd,
  postEdit,
  postFormData,
  postDelete,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";
import { serialize } from "object-to-formdata";

//If user is login then dispatch redux action's are directly from here.

function* StockPriceGet({ payload }) {
  // var params = {};
  // var data = payload.params;
  // for (const k in payload) {
  //   if (Object.hasOwnProperty.call(payload, k)) {
  //     if (k === "type") {
  //       params.type = payload.type.join(",");
  //     } else {
  //       if (payload[k] !== "" && k !== "no_of_pages") {
  //         params[k] = payload[k];
  //       }
  //     }
  //   }
  // }
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== null && k !== "no_of_pages") {
          if (k === "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k === "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/purchase/stockpricelist",
      params
    );
    yield put(stockPriceSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* StockPriceCountGet({ payload }) {
  // var data = payload;
  // var params = {};
  // for (const k in data) {
  //   if (Object.hasOwnProperty.call(data, k)) {
  //     if (k === "type") {
  //       params.type = data.type.join(",");
  //     } else {
  //       if (data[k] !== "" && k !== "no_of_pages") {
  //         params[k] = data[k];
  //       }
  //     }
  //   }
  // }
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== null && k !== "no_of_pages") {
          if (k === "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k === "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/purchase/stockpricelistcount",
      params
    );
    yield put(stockPriceCountSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* CommentList({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== null && k !== "no_of_pages") {
          if (k === "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k === "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/purchase/stockcomment/list/",
      params
    );
    yield put(commentListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* LocationList({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== null && k !== "no_of_pages") {
          if (k === "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k === "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      `/purchase/stockcurrentlocationbypurchase/${params?.id}`,params
      
    );
    yield put(locationListSuccessful(response));
  } 
  
  catch (error) {
    yield put(apiError(error));
  }
}

function* GalleryList({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== null && k !== "no_of_pages") {
          if (k === "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k === "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      `/purchase/stockimage/${data?.id}`,
      params
    );
    yield put(galleryListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* QsGalleryList({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== null && k !== "no_of_pages") {
          if (k === "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k === "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      `/purchase/quicksellimages/${data?.id}`,
      params
    );
    yield put(qsGalleryListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* QsGalleryListById({ payload }) {
  var data = payload;
  console.log("DATA",data)
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== null && k !== "no_of_pages") {
          if (k === "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k === "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      `/purchase/quicksellimages/${data?.id}`,
      params
    );
    yield put(qsGalleryListByIdSuccessful({response,id:data.id}));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* QsGalleryListForModel({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== null && k !== "no_of_pages") {
          if (k === "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k === "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      `/purchase/quicksellimages/${data?.id}`,
      params
    );
    yield put(qsGalleryListForModelSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* RatingList({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== null && k !== "no_of_pages") {
          if (k === "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k === "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/purchase/stockrating/listcreate/",
      params
    );
    yield put(ratingListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* GuarageRepairUpdate({ payload }) {
  try {
    if (payload.id == 0) {
      const response = yield call(
        postAdd,
        "/stocktransfer/guaragerepair/",
        payload
      );
      if (response.status === 201) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Guarage Repair Added Successfully.!
              </p>
            </div>
          ),
        });
        yield put(getStockPrice({ page: 1, search: "", page_size: 10 }));
        yield put(isGuarageModelVisible(false));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to added Guarage Repair. Please try again!
              </p>
            </div>
          ),
        });
      }
    } else {
      const response = yield call(
        postEdit,
        "/stocktransfer/guaragerepair/" + payload.id,
        payload
      );
      if (response.status === 200) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Guarage Repair Edited Successfully.!
              </p>
            </div>
          ),
        });
        yield put(getStockPrice({ page: 1, search: "", page_size: 10 }));
        yield put(isGuarageModelVisible(false));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to edit Guarage Repair. Please try again!
              </p>
            </div>
          ),
        });
      }
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* AddQsPrice({ payload }) {
  try {
    const formData = serialize(payload?.profileObj);
        const response = yield call(
          postEdit,
          "/purchase/quicksellimages/" + payload.qsPrice.id,
          formData
        );
   

    if (response) {
      if (response?.status === 200) {
        
        const response2 = yield call(
          postEdit,
          "/purchase/quicksell/" + payload.qsPrice.id,
          payload.qsPrice
        );
        if (response2.status === 200 || response2.status === 201) {
          if(response2.data.result){
          Modal.success({
            icon: (
              <span className="remix-icon">
                <RiCheckboxCircleLine />
              </span>
            ),
            title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
            content: (
              <div>
                <p className="hp-p1-body hp-text-color-black-80">
                  Your Vehicle added to Quick Sell Successfully.!
                </p>
              </div>
            ),
          })}else{
            Modal.error({
              icon: (
                <span className="remix-icon">
                  <RiAlertLine />
                </span>
              ),
              title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
              content: (
                <div>
                  <p className="hp-p1-body hp-text-color-black-80">
                  There was an issue adding your vehicle to Quick Sell. Please contact admin
                  </p>
                </div>
              ),
            });
          }
        }
        
        yield put(getStockPrice({ page: 1, search: "", page_size: 10 }));
        yield put(isQuickShellModelVisible({ status: false }));
        yield put(addedSuccess({ status: false }));
        yield put(addQsPriceSuccess(false))

      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to added vehicle into Quick Sell Please try again!
              </p>
            </div>
          ),
        });

        yield put(getStockPrice({ page: 1, search: "", page_size: 10 }));
        yield put(isQuickShellModelVisible({ status: false }));
        yield put(addedSuccess({ status: false }));
       
      }
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Unable to added vehicle into Quick Sell Please Check the
              configurations!
            </p>
          </div>
        ),
      });
      yield put(getStockPrice({ page: 1, search: "", page_size: 10 }));
      yield put(isQuickShellModelVisible({ status: false }));
      yield put(addedSuccess({ status: false }));
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* DeleteQs({ payload }) {
  try {
    const response = yield call(
      postAdd,
      "/purchase/quickselldelete/" + payload.id,
      payload
    );
    if (response.status === 200) {
      if(response.data.result){
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Stock has been Deleted Successfully.! From Quick Sell
              </p>
            </div>
          ),
        });
      }else{
          Modal.error({
            icon: (
              <span className="remix-icon">
                <RiAlertLine />
              </span>
            ),
            title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
            content: (
              <div>
                <p className="hp-p1-body hp-text-color-black-80">
                There was an issue Deleting your vehicle from Quick Sell. Please contact admin
                </p>
              </div>
            ),
          });
        }
     
      yield put(getStockPrice({ page: 1, search: "", page_size: 10 }));
      yield put(isQuickShellModelVisible({ status: false }));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Unable to Delete Quick Sell Stock. Please try again!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* UpdateOutOfStock({ payload }) {
  try {
    const response = yield call(
      postAdd,
      "/purchase/quicksellupdate/" + payload.id
    );
    if (response.status === 200) {
      if(response.data.result){
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Vehicle Stock Set to zero, Successfully.!
              </p>
            </div>
          ),
        });
      }else{
          Modal.error({
            icon: (
              <span className="remix-icon">
                <RiAlertLine />
              </span>
            ),
            title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
            content: (
              <div>
                <p className="hp-p1-body hp-text-color-black-80">
                There was an issue while Sold out your vehicle from Quick Sell. Please contact admin
                </p>
              </div>
            ),
          });
        } 
      yield put(getStockPrice({ page: 1, search: "", page_size: 10 }));
      yield put(isQuickShellModelVisible({ status: false }));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Unable Update Vehicle Stock to zero. Please try again!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* GuarageRepairById({ payload }) {
  console.log("GuarageRepairById", payload);
  try {
    const response = yield call(
      getParamsList,
      "/stocktransfer/guaragerepair/" + payload.row.guaragerepair?.id,
      {}
    );
    yield put(GuarageRepairByIdSuccessful({ response, type: payload.type }));
  } catch (error) {
    yield put(apiError(error));
  }
}

// function* qsImgGalleryClear({ payload }) {
//   try {
//     const response = yield call(
//       postDelete,
//       "/purchase/quicksellimages/" + payload?.id,
//       {}
//     );
//     if (response.status === 204) {
//       Modal.success({
//         icon: (
//           <span className="remix-icon">
//             <RiCheckboxCircleLine />
//           </span>
//         ),
//         title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
//         content: (
//           <div>
//             <p className="hp-p1-body hp-text-color-black-80">
//               Images Deleted Successfully.!
//             </p>
//           </div>
//         ),
//       });

//      yield put(isQuickShellModelVisible({ status: false }));
//      yield put(qsGalleryClearedSuccessfully());
//     } else {
//       Modal.error({
//         icon: (
//           <span className="remix-icon">
//             <RiAlertLine />
//           </span>
//         ),
//         title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
//         content: (
//           <div>
//             <p className="hp-p1-body hp-text-color-black-80">
//               Sorry! Unable Unable to delete. Please try again!
//             </p>
//           </div>
//         ),
//       });
//     }
//   } catch (error) {
//     yield put(apiError(error));
//   }
// }
function* qsImgGalleryClear({ payload }) {
  try {
    const response = yield call(
      postDelete,
      "/purchase/quicksellimages/" + payload?.id,
      {}
    );
    if (response.status === 204) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Images Deleted Successfully.!
            </p>
          </div>
        ),
      });

     yield put(isQuickShellModelVisible({ status: false }));
     yield put(getStockPrice({ page: 1, search: "", page_size: 10 }));
     window.location.reload();
     yield put(qsGalleryClearedSuccessfully());
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Unable Unable to delete. Please try again!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    yield put(apiError(error));
  }
}
function* IsRepairUpdate({ payload }) {
  let stockPriceParams = yield select(
    (state) => state.stockPrice.stockPriceParams
  );
  try {
    const response = yield call(
      postEdit,
      "/purchase/isrepair/" + payload.id,
      payload
    );
    if (response.status === 200) {
      if (response.data.is_repair == true) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Repair Access is granted for this vehicle
              </p>
            </div>
          ),
        });
      } else {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Repair Access is denied for this vehicle
              </p>
            </div>
          ),
        });
      }
      yield put(getStockPrice(stockPriceParams));
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });

    yield put(apiError(error));
  }
}

function* checkRTA({ payload }) {
  let stockPriceParams = yield select(
    (state) => state.stockPrice.stockPriceParams
  );
  try {
    const response = yield call(
      postEdit,
      "/purchase/isrta/" + payload.id,
      payload
    );
    if (response.status === 200) {
      if (response.data.is_rta == true) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
              RTA is done for this vehicle
              </p>
            </div>
          ),
        });
      } else {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
              RTA is not done for this vehicle
              </p>
            </div>
          ),
        });
      }
      yield put(getStockPrice(stockPriceParams));
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });

    yield put(apiError(error));
  }
}
function* QuickShellImageUpload({ payload }) {
  try {
    const response = yield call(postFormData, "/system/attachment/", {
      file: payload.file,
    });

    yield put(
      quickShellImageUploadSuccessful({ response, ImageType: payload.imageType })
    );
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchGetStockPrice() {
  yield takeEvery("stockPrice/getStockPrice", StockPriceGet);
}
export function* watchGetStockPriceCount() {
  yield takeEvery("stockPrice/getStockPriceCount", StockPriceCountGet);
}
export function* watchGetCommentList() {
  yield takeEvery("stockPrice/getCommentList", CommentList);
}
export function* watchGetLocationList() {
  yield takeEvery("stockPrice/getLocationList", LocationList);
}
export function* watchGetGalleryList() {
  yield takeEvery("stockPrice/getGalleryList", GalleryList);
}
export function* watchGetQsGalleryList() {
  yield takeEvery("stockPrice/getQsGalleryList", QsGalleryList);
}
export function* watchGetQsGalleryListById() {
  yield takeEvery("stockPrice/getQsGalleryListById", QsGalleryListById);
}
export function* watchGetGetQsGalleryListForModel(){
  yield takeEvery("stockPrice/getQsGalleryListForModel", QsGalleryListForModel)
}
export function* watchGetRatingList() {
  yield takeEvery("stockPrice/getRatingList", RatingList);
}
export function* watchUpdateGuarageRepair() {
  yield takeEvery("stockPrice/updateGuarageRepair", GuarageRepairUpdate);
}
export function* watchAddQsPrice() {
  yield takeEvery("stockPrice/addQsPrice", AddQsPrice);
}
export function* watchDeleteQs() {
  yield takeEvery("stockPrice/deleteQs", DeleteQs);
}

export function* watchUpdateOutOfStock() {
  yield takeEvery("stockPrice/updateOutOfStock", UpdateOutOfStock);
}

export function* watchGetGuarageRepairById() {
  yield takeEvery("stockPrice/getGuarageRepairById", GuarageRepairById);
}
export function* watchUpdatIsRepair() {
  yield takeEvery("stockPrice/updatIsRepair", IsRepairUpdate);
}
export function* watchcheckRTA() {
  yield takeEvery("stockPrice/checkRTA", checkRTA);
}
export function* watchGetQuickShellImageUpload() {
  yield takeEvery("stockPrice/getQuickShellImageUpload", QuickShellImageUpload);
}
export function* watchQsGalleryClear() {
  yield takeEvery("stockPrice/qsGalleryClear", qsImgGalleryClear);
}
function* StockPriceSaga() {
  yield all([
    fork(watchGetStockPrice),
    fork(watchGetStockPriceCount),
    fork(watchGetCommentList),
    fork(watchGetLocationList),
    fork(watchGetGalleryList),
    fork(watchGetQsGalleryList),
    fork(watchGetGetQsGalleryListForModel),
    fork(watchGetQsGalleryListById),
    fork(watchGetRatingList),
    fork(watchUpdateGuarageRepair),
    fork(watchGetGuarageRepairById),
    fork(watchUpdatIsRepair),
    fork(watchAddQsPrice),
    fork(watchUpdateOutOfStock),
    fork(watchDeleteQs),
    fork(watchcheckRTA),
    fork(watchGetQuickShellImageUpload),
    fork(watchQsGalleryClear)
  ]);
}

export default StockPriceSaga;
