import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { act } from "react";

export const gateOutSlice = createSlice({
  name: "gateOut",
  initialState: {
    loginError: "aaa",
    message: null,
    loading: false,
    formSubmitLoading: false,
    apiError: {},
    listCount: 0,
    drawer: false,
    purchaseHistoryList: [],
    saleHistoryList: [],
    saleListCount: 0,
    repairAmountList: [],
    reEstimationList: [],
    gateOutList: [],
    purchaseEnquiryList: [],
    gateOutData: {
      riseError:false,
      riseErrorForNotRelease:false,
    },
    purchaseRejectData: {},
    vehicleParams: {
      start_date: "",
      end_date: "",

      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    godownParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    imageLooading: false,

    checkApprove: false,
    vehicleVersion: {},
    insuranceData: {},
    customerByIdList: {},
    gateInDataById: {},
    vehicleData: {},
    RejectMode: false,
    gateOutParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      // currentSort: "code",
      // sortOrder: "-",
    },
    rejectReasonObjData: {},
    filterStatus: false,
    vehicleHistoryCount: 0,
    saleHistoryCount: 0,
  },
  reducers: {
    tabClearData: (state, action) => {
      return {
        ...state,
        vehicleHistoryCount: 0,
        saleHistoryCount: 0,
      };
    },
    getGateOutList: (state, action) => {
      if (state.gateOutParams?.page == 1) {
        return {
          ...state,
          loading: true,
          gateOutList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    getGateInListById: (state, action) => {
      console.log("hello world");
      if (state.gateOutParams?.page == 1) {
        return {
          ...state,
          loading: true,
          gateOutList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    gateInListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.gateOutParams.page_size
      );
      return {
        ...state,
        listCount: action.payload.response.count,
        gateOutList: action.payload.response.results,
        // gateOutList: [
        //   ...state.gateOutList,
        //   ...action.payload.response.results,
        // ],
        loading: false,
        gateOutParams: {
          ...state.gateOutParams,
          no_of_pages: noofpages,
        },
      };
    },

    getGateInListByIdSuccessful: (state, action) => {
      console.log("action payload..", payload);
      return {
        ...state,
        gateOutList: action.payload,
        loading: false,
      };
    },

    clearPurchaseList: (state, action) => {
      return {
        ...state,
        loading: true,
        gateOutList: [],
      };
    },
    SetVehicleParams: (state, action) => {
      return {
        ...state,
        vehicleParams: action.payload,
      };
    },
    SetGodownParams: (state, action) => {
      return {
        ...state,
        godownParams: action.payload,
      };
    },

    setGateOutData: (state, action) => {
      return{
        ...state,
        gateOutData:{}
      }


    },

    // Purchase Vehicle Historty

    getPurchaseHistory: (state, action) => {
      return {
        ...state,
        loading: true,
        purchaseHistoryList: [],
      };
    },
    addImageUpload: (state, action) => {
      return {
        ...state,
        // imageLooading: true,
        // vehicleImg: [...state.vehicleImg, action.payload],
        // estimationData: {...state.estimationData,   ...action.payload}
        gateOutData: {
          ...state.gateOutData,
          [action.payload.imageType]: action.payload.file,
        }
      };
    },
    setPreviewImg: (state, action) => {
      return{
        ...state,
        // imageLooading: false,
        gateOutData: {
          ...state.gateOutData,
          previewFile: action.payload,
        }
      }
    },
    purchaseHistoryListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.gateOutParams.page_size
      );

      return {
        ...state,
        vehicleHistoryCount: action.payload.response.count,
        purchaseHistoryList: action.payload.response.results,
        loading: false,
        formSubmitLoading: false,
        gateOutParams: {
          ...state.gateOutParams,
          no_of_pages: noofpages,
        },
      };
    },

    //  Sale Vehicle Historty

    getSaleHistory: (state, action) => {
      return {
        ...state,
        loading: true,
        saleHistoryList: [],
      };
    },

    saleHistoryListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.gateOutParams.page_size
      );

      return {
        ...state,
        // saleListCount: action.payload.response.count,
        saleHistoryCount: action.payload.response.count,
        saleHistoryList: action.payload.response.results,
        loading: false,
        gateOutParams: {
          ...state.gateOutParams,
          no_of_pages: noofpages,
        },
      };
    },

    // Repair Amount

    getRepairAmount: (state, action) => {
      return {
        ...state,
        loading: true,
        listCount: 0,
        repairAmountList: [],
      };
    },

    repairAmountListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.gateOutParams.page_size
      );

      return {
        ...state,
        listCount: action.payload.response.count,
        repairAmountList: action.payload.response.results,
        loading: false,
        gateOutParams: {
          ...state.gateOutParams,
          no_of_pages: noofpages,
        },
      };
    },

    // Re Estimation

    getReEstimation: (state, action) => {
      return {
        ...state,
        loading: true,
        listCount: 0,
        reEstimationList: [],
      };
    },

    reEstimationListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.gateOutParams.page_size
      );

      return {
        ...state,
        listCount: action.payload.response.count,
        reEstimationList: action.payload.response.results,
        loading: false,
        gateOutParams: {
          ...state.gateOutParams,
          no_of_pages: noofpages,
        },
      };
    },

    addGateOut: (state, action) => {
      return {
        ...state,
        formSubmitLoading: true,
      };
    },
    addGateOutSuccessful: (state, action) => {
      return {
        ...state,
        formSubmitLoading: false,
      };
    },
    purchaseClear: (state, action) => {
      return {
        ...state,
        loading: false,
        gateOutData: {},
        vehicleVersion: {},
        insuranceData: {},
      };
    },
    getPurchaseDoc: (state, action) => {
      return {
        ...state,
        loading: true,
        gateOutData: {},
        vehicleVersion: {},
        insuranceData: {},
      };
    },
    purchaseDocSuccessful: (state, action) => {
      return {
        ...state,
        gateOutData: {
          ...action.payload,
          dealer_id: action.payload.dealer?.id,
          financierbank_id: action.payload.financierbank?.id,
          tokendate:
            action.payload.tokendate == null
              ? null
              : moment(action.payload?.tokendate),
          insurancecompany_id: action.payload.insurancecompany?.id,
          insuranceexpdate:
            action.payload.insuranceexpdate == null
              ? null
              : moment(action.payload?.insuranceexpdate),
          customer_id: action.payload.customer?.id,
          internalfinancier_id: action.payload.internalfinancier?.id,
          counter_id: action.payload.counter?.id,
          vehicle_id: action.payload.vehicle?.id,
          color_id: action.payload.color?.id,
          mcinternal_id: action.payload.mcinternal?.id,
          addressproof1_id: action.payload.addressproof1?.id,
          addressproof2_id: action.payload.addressproof2?.id,
          externalmcbank_id: action.payload.externalmcbank?.id,
          mcinternal_id: action.payload.mcinternal?.id,
          insurance_id: action.payload.insurance?.code,
          // insuranceamount: action.payload.insurance?.customeramount,
          insuranceamount: action.payload.insuranceamount,
          requestedamount: action.payload.insurance?.requestedamount,
        },
        loading: false,
        saleListCount: 0,
      };
    },

    getGateOutByID: (state, action) => {
      return {
        ...state,
        loading: true,
        gateInDataById: {},
      };
    },

    gateOutByIDSuccessful: (state, action) => {
      return {
        ...state,
        gateOutData: {
          ...state.gateOutData,
          ...action.payload.response,
          vehicle_id: action.payload.response.vehicle ? {
            vehicle_name : action.payload.response.vehicle.vehicleno,
            vehicle_id : action.payload.response.vehicle.id,
          } : "",
          godown_id: action.payload.response.godown ? {
            godown_name : action.payload.response.godown.name,
            godown_id : action.payload.response.godown.id,
          } : "",
          lotno: action.payload.response.lot_no,
          description: action.payload.response.out_description,
        },
        loading: false,
      };
    },
    getvehicleImagesByID: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    vehicleImagesByIDSuccessful: (state, action) => {
    console.log("action payload...",action.payload)
      // Destructure the response for cleaner code
      const {
        confirmation_image1,
        confirmation_image2,
      } = action.payload.response;
      return {
        ...state,
        loading: false,
        gateOutData: {
          ...state.gateOutData,
          ...action.payload.response,

        },
      };
    },
    
    getVehicleVersion: (state, action) => {
      return {
        ...state,
        loading: true,
        vehicleVersion: action.payload,
      };
    },
    getInsuranceData: (state, action) => {
      return {
        ...state,
        loading: true,
        insuranceData: action.payload,
      };
    },
    getVehicleData: (state, action) => {
      return {
        ...state,
        loading: true,
        vehicleData: {},
      };
    },
    vehicleDataSuccessful: (state, action) => {
      return {
        ...state,
        vehicleData: action.payload.response,
        loading: false,
      };
    },
    setRiseError : (state, action) => {
      return{
        ...state,
        gateOutData:{
          ...state.gateOutData,
          riseError:action.payload
        }
      }
    },
    setRiseErrorForPurchase:(state,action) => {
      return{
        ...state,
        gateOutData:{
          ...state.gateOutData,
          riseErrorForPurchase:action.payload
        }
      }
    },
    setRiseErrorForNotRelease : (state, action) => {
      return{
        ...state,
        gateOutData:{
          ...state.gateOutData,
          riseErrorForNotRelease:action.payload
        }
      }
    },
    InputChangeValue: (state, action) => {
      if(action.payload?.key === "vehicle_id"){
        const {godown_transfer_out} = action.payload?.value
        return{
          ...state,
          gateOutData:{
            ...state.gateOutData,
            godown_transfer_out,
            godown_vehicle:action.payload.value?.godown_vehicle,
            seizing_release: action.payload.value?.seizing_release || null,
            vehicle_id:{
            vehicle_id:action.payload.value?.id,
            vehicle_name:action.payload.value?.vehicle?.vehicleno,
            },
            godown_id:{
              godown_id:action.payload.value?.godown?.id,
              godown_name:action.payload.value?.godown?.name,
            },
            release_id:{
              release_id:action.payload.value?.id,
              release_code:action.payload.value?.code
            },
            counter_id:{
              counter_id: action.payload?.counter?.id || null,
              counter_name: action.payload?.counter?.name || "",
            },
            ownplace:{
              ownplace:action.payload.value?.own_place
            },
            location_type:action.payload.value?.location_type,
            is_released:action.payload.value?.is_released,
            riseError:false,
            riseErrorForNotRelease:false,
            is_godown_transferred:action.payload.value?.is_godown_transferred,
            riseErrorForPurchase:false,

          }
        }
      }
      if(action.payload.key === "reason_id"){
       if(state.gateOutData.is_released){
        if(action.payload.value?.id !== 1){
          return{
            ...state,
            gateOutData:{
              ...state.gateOutData,
              reason_id:{},
              riseError:true
            }
          }
        }
        else{
          return{
            ...state,
            gateOutData:{
              ...state.gateOutData,
              reason_id:action.payload.value,
              riseError:false
            }
          }
        }
       }
       if(state.gateOutData.is_godown_transferred){
        if(action.payload.value?.id !==3 ){
          return{
            ...state,
            gateOutData:{
              ...state.gateOutData,
              reason_id:{},
              riseErrorForPurchase:true
            }
          }
        }
        else{
          return{
            ...state,
            gateOutData:{
              ...state.gateOutData,
              reason_id:action.payload.value,
              riseErrorForPurchase:false
            }
          }
        }
       }
       else{
        if(action.payload.value?.id === 1){
          return{
            ...state,
            gateOutData:{
              ...state.gateOutData,
              reason_id:{},
              riseErrorForNotRelease:true
            }
          }
        }
       else if(action.payload.value?.id === 3){
          return{
            ...state,
            gateOutData:{
              ...state.gateOutData,
              riseErrorForNotRelease:true
            }
          }
        }
        else{
          return{
            ...state,
            gateOutData:{
              ...state.gateOutData,
              reason_id:action.payload.value,
              riseError:false,
              riseErrorForNotRelease:false
            }
          }
        }

       }
  
      }
      else{
      return {
        ...state,
        gateOutData: {
          ...state.gateOutData,
          [action.payload.key]: action.payload.value,
        },
      };
    }
    },

    // InputChangeValue: (state, action) => {
    //   switch (action.payload.key) {
    //     case "partytype":
    //       if (action.payload.value == 4 || action.payload.value == 5) {
    //         return {
    //           ...state,
    //           gateOutData: {
    //             counter_id: state.gateOutData.counter_id,
    //             mc_internal_type: 3,
    //             [action.payload.key]: action.payload.value,
    //           },
    //         };
    //       } else {
    //         return {
    //           ...state,
    //           gateOutData: {
    //             counter_id: state.gateOutData.counter_id,
    //             [action.payload.key]: action.payload.value,
    //           },
    //         };
    //       }
    //     case "hypothecation":
    //       return {
    //         ...state,
    //         gateOutData: {
    //           ...state.gateOutData,
    //           [action.payload.key]: action.payload.value,
    //           hypothecationisinternal: false,
    //           internalfinancier_id: null,
    //           externalfinanciername: null,
    //           financierbank_id: null,
    //           financieraccountnumber: null,
    //           financierifsccode: null,
    //           loanstatus: false,
    //           loanamount: null,
    //           loannumber: null,
    //         },
    //       };

    //     case "vehicle_id":
    //       return {
    //         ...state,
    //         gateOutData: {
    //           ...state.gateOutData,
    //           [action.payload.key]: action.payload.value,
    //           insurancecompany_id: null,
    //           insurancenumber: null,
    //           insuranceexpdate: null,
    //           is_insurance_raised: null,
    //           raise_insurance: null,
    //           requestedamount: null,
    //           insurance_id: null,
    //           insuranceamount: null,
    //           customeramount: null,
    //         },
    //       };

    //     case "loanstatus":
    //       return {
    //         ...state,
    //         gateOutData: {
    //           ...state.gateOutData,
    //           [action.payload.key]: action.payload.value,
    //           hypothecationisinternal: false,
    //           internalfinancier_id: null,
    //           externalfinanciername: null,
    //           financierbank_id: null,
    //           financieraccountnumber: null,
    //           financierifsccode: null,
    //           loanamount: null,
    //         },
    //       };

    //     case "hypothecationisinternal":
    //       return {
    //         ...state,
    //         gateOutData: {
    //           ...state.gateOutData,
    //           [action.payload.key]: action.payload.value,
    //           externalfinanciername: null,
    //           financierbank_id: null,
    //           financieraccountnumber: null,
    //           financierifsccode: null,
    //           internalfinancier_id: null,
    //         },
    //       };

    //       break;

    //     case "tokenstatus":
    //       return {
    //         ...state,
    //         gateOutData: {
    //           ...state.gateOutData,
    //           [action.payload.key]: action.payload.value,
    //           tokennumber: null,
    //           tokendate: null,
    //         },
    //       };

    //     case "insurancestatus":
    //       return {
    //         ...state,
    //         gateOutData: {
    //           ...state.gateOutData,
    //           [action.payload.key]: action.payload.value,
    //           insurancecompany_id: null,
    //           insurancenumber: null,
    //           insuranceexpdate: null,
    //         },
    //       };

    //     case "iscustomcolor":
    //       return {
    //         ...state,
    //         gateOutData: {
    //           ...state.gateOutData,
    //           [action.payload.key]: action.payload.value,
    //           color_id: null,
    //           customcolor: null,
    //         },
    //       };

    //     case "mc_internal_type":
    //       if (action.payload.value == 1) {
    //         return {
    //           ...state,
    //           gateOutData: {
    //             ...state.gateOutData,
    //             [action.payload.key]: action.payload.value,
    //           },
    //         };
    //       } else if (action.payload.value == 2) {
    //         return {
    //           ...state,
    //           gateOutData: {
    //             ...state.gateOutData,
    //             [action.payload.key]: action.payload.value,
    //             externalmcname: null,
    //             externalmcmobile: null,
    //             externalmcbank_id: null,
    //             externalmcbankacno: null,
    //             externalmcifsccode: null,
    //           },
    //         };
    //       } else if (action.payload.value == 3) {
    //         return {
    //           ...state,
    //           gateOutData: {
    //             ...state.gateOutData,
    //             [action.payload.key]: action.payload.value,
    //             mcamount: null,
    //             externalmcname: null,
    //             externalmcmobile: null,
    //             externalmcbank_id: null,
    //             externalmcbankacno: null,
    //             externalmcifsccode: null,
    //             mcinternal_id: null,
    //           },
    //         };
    //       }

    //     default:
    //       return {
    //         ...state,
    //         gateOutData: {
    //           ...state.gateOutData,
    //           [action.payload.key]: action.payload.value,
    //         },
    //       };
    //   }
    // },
    getPurchasesImageUpload: (state, action) => {
      return {
        ...state,
        loading: true,
        imageLooading: true,
        gateOutData: {
          ...state.gateOutData,
          [action.payload.imageType]: null,
        },
      };
    },
    purchasesImageUploadSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        imageLooading: false,
        gateOutData: {
          ...state.gateOutData,
          [action.payload.ImageType]: action.payload.response.data,
          [action.payload.ImageType + "_id"]: action.payload.response.data.id,
        },
      };
    },
    getFormulaExecuter: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    FormulaExecuterSuccessful: (state, action, code) => {
      if (action.payload.formulaCode == "ESTIMATIONREPAIR") {
        return {
          ...state,
          loading: false,
          gateOutData: {
            ...state.gateOutData,
            totalrepairamount: parseFloat(action.payload.response).toFixed(2),
          },
        };
      } else if (action.payload.formulaCode == "PURCUSTOMERPAY") {
        return {
          ...state,
          loading: false,
          gateOutData: {
            ...state.gateOutData,
            customerpaybleamount: parseFloat(action.payload.response).toFixed(
              2
            ),
            customercreditamount: 0,
            customerbankamount: 0,
            customercashamount: parseFloat(action.payload.response).toFixed(2),
          },
        };
      } else if (action.payload.formulaCode == "CASHAMOUNT") {
        return {
          ...state,
          loading: false,
          gateOutData: {
            ...state.gateOutData,
            customercashamount: parseFloat(action.payload.response).toFixed(2),
          },
        };
      } else if (action.payload.formulaCode == "PURTOTALPAY") {
        return {
          ...state,
          loading: false,
          gateOutData: {
            ...state.gateOutData,
            total: parseFloat(action.payload.response).toFixed(2),
          },
        };
      }
    },
    purchaseApproval: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    getPurchaseCheckApprove: (state) => {
      return {
        ...state,
        loading: true,
        checkApprove: false,
      };
    },
    purchasesCheckApproveSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        checkApprove: action.payload,
      };
    },
    isRejectModelVisible: (state, action) => {
      return {
        ...state,
        RejectMode: action.payload,
        purchaseRejectData: {},
        loading: false,
      };
    },
    rejectModelInputChangeValue: (state, action) => {
      return {
        ...state,
        purchaseRejectData: {
          ...state.purchaseRejectData,
          [action.payload.key]: action.payload.value,
        },
        loading: false,
      };
    },
    getPurchaseEnquiry: (state) => {
      return {
        ...state,
        purchaseEnquiryList: [],
        loading: true,
      };
    },
    purchaseEnquirySuccessful: (state, action) => {
      return {
        ...state,
        purchaseEnquiryList: action.payload.results,
        loading: false,
      };
    },
    gateInDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    setGateInParams: (state, action) => {
      return {
        ...state,
        gateOutParams: action.payload,
      };
    },

    isDrawerVisible: (state, action) => {
      return {
        ...state,
        drawer: action.payload,
      };
    },
    FilterInputChangeValue: (state, action) => {
      switch (action.payload.key) {
        case "counter__zone":
          return {
            ...state,
            gateOutParams: {
              ...state.gateOutParams,
              [action.payload.key]: action.payload.value,
              counter__cluster: null,
              counter__division: null,
              counter: null,
            },
          };

        case "counter__division":
          return {
            ...state,
            gateOutParams: {
              ...state.gateOutParams,
              [action.payload.key]: action.payload.value,
              counter__cluster: null,
              counter: null,
            },
          };

        case "counter__cluster":
          return {
            ...state,
            gateOutParams: {
              ...state.gateOutParams,
              [action.payload.key]: action.payload.value,
              counter: null,
            },
          };
        default:
          return {
            ...state,
            gateOutParams: {
              ...state.gateOutParams,
              [action.payload.key]: action.payload.value,
            },
          };
      }
      // return {
      //   ...state,
      //   gateOutParams: {
      //     ...state.gateOutParams,
      //     [action.payload.key]: action.payload.value,
      //   },
      // };
    },
    getCustomerById: (state, action) => {
      return {
        ...state,
        loading: true,
        customerByIdList: action.payload,
      };
    },
    insuranceNumberValidate: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    getRejectReasonData: (state, action) => {
      return {
        ...state,
        loading: true,
        rejectReasonObjData: action.payload,
      };
    },
    fsyncStatusUpdate: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    checkFilterStatus: (state, action) => {
      return {
        ...state,
        filterStatus: action.payload,
      };
    },
    apiError: (state, action) => {
      return {
        ...state,
        loading: false,
        formSubmitLoading: false,
        error_msg: action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getGateOutList,
  purchaseListSuccessful,
  gateInListSuccessful,
  getGateInListById,
  getGateInListByIdSuccessful,
  clearPurchaseList,
  addGateOut,
  addGateOutSuccessful,
  purchaseClear,
  getPurchaseDoc,
  SetVehicleParams,
  purchaseDocSuccessful,
  getVehicleVersion,
  getInsuranceData,
  InputChangeValue,
  getGateOutByID,
  gateOutByIDSuccessful,
  getVehicleData,
  vehicleDataSuccessful,
  getPurchasesImageUpload,
  purchasesImageUploadSuccessful,
  getFormulaExecuter,
  FormulaExecuterSuccessful,
  purchaseApproval,
  getPurchaseCheckApprove,
  purchasesCheckApproveSuccessful,
  isRejectModelVisible,
  rejectModelInputChangeValue,
  getPurchaseEnquiry,
  purchaseEnquirySuccessful,
  setGateInParams,
  getPurchaseHistory,
  purchaseHistoryListSuccessful,
  getSaleHistory,
  saleHistoryListSuccessful,
  gateInDelete,
  isDrawerVisible,
  FilterInputChangeValue,
  getRepairAmount,
  repairAmountListSuccessful,
  getReEstimation,
  reEstimationListSuccessful,
  getCustomerById,
  insuranceNumberValidate,
  getRejectReasonData,
  fsyncStatusUpdate,
  checkFilterStatus,
  tabClearData,
  SetGodownParams,
  apiError,
  setGateOutData,
  setRiseError,
  setRiseErrorForPurchase,
  addImageUpload,
  setPreviewImg,
  setRiseErrorForNotRelease,
  getvehicleImagesByID,
  vehicleImagesByIDSuccessful
} = gateOutSlice.actions;

export default gateOutSlice.reducer;
