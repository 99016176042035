import { all } from "redux-saga/effects";

// login
import loginSaga from "./auth/login/saga";

// notification
import notificationSaga from "./notifications/saga";

//userProfile
import userProfileSaga from "./userProfile/saga";

// Settings
import ManageUsersSaga from "./settings/ManageUsers/saga";
import ManageSeizingOfficerSaga from "./settings/ManageSeizingOfficer/saga"
import ManageProfileSaga from "./settings/ManageProfile/saga";
import ManagersSaga from "./settings/Managers/saga";
import LocationManagerSaga from "./settings/LocationManager/saga";
import ApprovalSaga from "./settings/Approval/saga";
import ImportExportSaga from "./settings/ImportExport/saga";
import BackupDatabaseSaga from "./settings/BackupDatabse/saga";
import ManageCompanySaga from "./settings/ManageCompany/saga";
import AppSettingsSaga from "./settings/AppSettings/saga";

//mini
import miniSaga from "./mini/saga";

// Masters
import CountrySaga from "./Masters/Country/saga";
import MenuSaga from "./Masters/Menus/saga";
import MenuItemSaga from "./Masters/MenuItems/saga";
import MastersMenuSaga from "./Masters/MastersMenu/saga";
import StateSaga from "./Masters/State/saga";
import DistrictSaga from "./Masters/District/saga";
import CitySaga from "./Masters/City/saga";
import AreaSaga from "./Masters/Area/saga";
import ZonesSaga from "./Masters/Zones/saga";
import FirmSaga from "./Masters/Firm/saga";
import PersonSaga from "./Masters/Person/saga";
import BrandSaga from "./Masters/Brand/saga";
import EmissionnormsSaga from "./Masters/Emissionnorms/saga";
import OccupationsSaga from "./Masters/Occupations/saga";
import ColorsSaga from "./Masters/Colors/saga";
import BanksSaga from "./Masters/Banks/saga";
import PrioritySaga from "./Masters/Priority/saga";
import CompanybanksSaga from "./Masters/Companybanks/saga";
import DivisionsSaga from "./Masters/Divisions/saga";
import ClustersSaga from "./Masters/Clusters/saga";
import MechanicsSaga from "./Masters/Mechanics/saga";
import DealersSaga from "./Masters/Dealers/saga";
import BrandfamilySaga from "./Masters/Brandfamily/saga";
import ModelsSaga from "./Masters/Models/saga";
import AddressproofSaga from "./Masters/Addressproof/saga";
import EmidatesSaga from "./Masters/Emidates​/saga";
import InsuranceSaga from "./Masters/Insurance/saga";
import LeavereasonsSaga from "./Masters/Leavereasons/saga";
import VersionfeaturesSaga from "./Masters/Versionfeatures​/saga";
import RejectreasonsSaga from "./Masters/Rejectreasons/saga";
import CounterSaga from "./Masters/Counters/saga";
import GodownSaga from "./Masters/Godown/saga";
import FormulaSaga from "./Masters/Formulas/saga";
import VehicletypesSaga from "./Masters/Vehicletypes/saga";
import ShowroomsSaga from "./Masters/Showrooms/saga";
import PercentageinterestsSaga from "./Masters/Percentageinterests/saga";
import FinanciersSaga from "./Masters/Financiers/saga";
import VersionsSaga from "./Masters/Versions/saga";
import VehiclesSaga from "./Masters/Vehicles/saga";
import SalequotationlistSaga from "./Masters/Salequotationlist/saga";
import TenuresSaga from "./Masters/Tenures/saga";
import DepartmentSaga from "./Masters/Departments/saga";
import ReEstimationSaga from "./Masters/ReEstimation/saga";
import RepairAmountSaga from "./Masters/RepairAmount/saga";
import FvQuestionSaga from "./Masters/FvQuestions/saga";
import RepairTypes from "./Masters/RepairTypes/saga";
import BannersAdSaga from "./Masters/BannersAds/saga"
import assetSaga from "./Masters/Assets/saga";
import OrganizationSaga from "./Masters/HashCloudOrganization/saga";
import DailyExpenseSaga from "./Masters/DailyExpenses/saga";
import CategorySaga from "./Masters/Category/saga";
import SubCategorySaga from "./Masters/SubCategory/saga";
import ItemSaga from "./Masters/Items/saga";
import ServiceMechSaga from "./Masters/ServiceMechanic/saga";
import RampSaga from "./Masters/Ramp/saga";

// Reports
import reportsSaga from "./reports/saga";
// System APIs
import menuItemSaga from "./system/saga";
// Side Menu
import purchaseSaga from "./sideMenu/purchases/saga";
import godownTransferOutSaga from "./sideMenu/godownTransferOut/saga";
import gateInSaga from "./sideMenu/gateIn/saga";
import seizingVehiclesSaga from "./sideMenu/seizingVehicle/saga";
import SeizingReleaseSaga from "./sideMenu/seizingRelease/saga";
import SeizingVehiclesApprovedSaga from "./sideMenu/seizingApprovedVehicles/saga";
import gateOutSaga from "./sideMenu/gateout/saga"
import qsLeadSage from './sideMenu/QsLeads/saga'
import vehicleCounterSalesSaga from "./sideMenu/vehicleCounterSales/saga";
import EnquirySaga from "./sideMenu/Enquiry/saga";
import stockRequestSaga from "./sideMenu/StockTransfer/StockRequest/saga";
import newInsuranceSaga from "./sideMenu/newInsurance/saga";
import rtoSaga from "./sideMenu/rto/saga";
import transactionApproveSaga from "./sideMenu/transactionApprove/saga";
import stockSaga from "./sideMenu/stock/saga";
import myStockSaga from "./sideMenu/mystock/saga";
import StockPriceSaga from "./sideMenu/stockPriceView/saga";
import targetSaga from "./sideMenu/targets/saga";
import dashboardSaga from "./dashboard/saga";
import customerDefaulterSaga from "./sideMenu/customerDefaulter/saga";
import requestStockSaga from "./sideMenu/stockRequest/saga";
import leaveApproveSaga from "./sideMenu/leaveApprove/saga";
import AssetRquestSaga from "./sideMenu/assetRequest/saga";
import visitSaga from "./sideMenu/visit/saga";
import manualAttendanceSaga from "./sideMenu/manualAttendance/saga";
import vehicleLogSaga from "./sideMenu/vehicleLog/saga";
import customerLogSaga from "./sideMenu/customerLog/saga";
import idealStockSaga from "./sideMenu/idealStock/saga";
import qrSaga from "./sideMenu/QRCode/saga";
import dailyPaymentSaga from "./sideMenu/dailyPayments/saga";
import refurbishedSaga from "./sideMenu/Refurbished/saga";
import vehicleEstimationSaga from "./sideMenu/vehicleEstimation/saga";
import vehicleImageSaga from "./sideMenu/vehicleImage/saga";
import jobCardSaga from "./sideMenu/jobCard/saga";
import material_IssueSaga from "./sideMenu/material_issue/saga";

export default function* rootSaga() {
  yield all([
    // login
    loginSaga(),

    //notification
    notificationSaga(),

    //userProfile
    userProfileSaga(),

    // Settings
    ManageUsersSaga(),
    ManageSeizingOfficerSaga(),
    ManageProfileSaga(),
    ManagersSaga(),
    LocationManagerSaga(),
    ApprovalSaga(),
    ImportExportSaga(),
    BackupDatabaseSaga(),
    ManageCompanySaga(),
    AppSettingsSaga(),

    // mini
    miniSaga(),

    // Masters
    DepartmentSaga(),
    CounterSaga(),
    GodownSaga(),
    CountrySaga(),
    MenuSaga(),
    MenuItemSaga(),
    MastersMenuSaga(),
    StateSaga(),
    DistrictSaga(),
    CitySaga(),
    AreaSaga(),
    ZonesSaga(),
    FirmSaga(),
    PersonSaga(),
    BrandSaga(),
    EmissionnormsSaga(),
    OccupationsSaga(),
    ColorsSaga(),
    BanksSaga(),
    PrioritySaga(),
    CompanybanksSaga(),
    DivisionsSaga(),
    ClustersSaga(),
    MechanicsSaga(),
    DealersSaga(),
    BrandfamilySaga(),
    ModelsSaga(),
    AddressproofSaga(),
    EmidatesSaga(),
    InsuranceSaga(),
    LeavereasonsSaga(),
    VersionfeaturesSaga(),
    RejectreasonsSaga(),
    VehicletypesSaga(),
    ShowroomsSaga(),
    PercentageinterestsSaga(),
    FinanciersSaga(),
    VersionsSaga(),
    VehiclesSaga(),
    SalequotationlistSaga(),
    FormulaSaga(),
    TenuresSaga(),
    ReEstimationSaga(),
    RepairAmountSaga(),
    FvQuestionSaga(),
    RepairTypes(),
    BannersAdSaga(),
    assetSaga(),
    OrganizationSaga(),
    CategorySaga(),
    SubCategorySaga(),
    ItemSaga(),
    ServiceMechSaga(),
    RampSaga(),
    //Reports
    reportsSaga(),
    // system APIs
    menuItemSaga(),
    // Side Menu
    purchaseSaga(),
    godownTransferOutSaga(),
    gateInSaga(),
    gateOutSaga(),
    qsLeadSage(),
    seizingVehiclesSaga(),
    SeizingReleaseSaga(),
    SeizingVehiclesApprovedSaga(),
    vehicleCounterSalesSaga(),
    EnquirySaga(),
    stockRequestSaga(),
    newInsuranceSaga(),
    rtoSaga(),
    transactionApproveSaga(),
    stockSaga(),
    myStockSaga(),
    StockPriceSaga(),
    targetSaga(),
    dashboardSaga(),
    customerDefaulterSaga(),
    requestStockSaga(),
    leaveApproveSaga(),
    AssetRquestSaga(),
    visitSaga(),
    manualAttendanceSaga(),
    vehicleLogSaga(),
    customerLogSaga(),
    idealStockSaga(),
    qrSaga(),
    dailyPaymentSaga(),
    refurbishedSaga(),
    DailyExpenseSaga(),
    vehicleEstimationSaga(),
    vehicleImageSaga(),
    jobCardSaga(),
    material_IssueSaga(),
  ]);
}
